.float-right {
  float: right;
}

.image-container {
  align-self: center;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.summary {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sentiment-label {
  height: fit-content;
}

.masonry-grid {
  column-count: 2;
  column-gap: 1em;
}

.masonry-grid-item {
  display: inline-block;
  margin: 0 0 1em;
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .masonry-grid {
    column-count: 1;
  }
}
