:root {
  --primary-color: #e80074;
  --primary-color-hover: #00cfe5;
  --primary-color-text: #ffffff;
  --text-color: #ffffff;
  --text-color-secondary: #717171;
  --error: #e24c4c;
  --surface-header: #18191d;
  --surface-content: #080718;
  --surface-border: #383838;
  --surface-section: #18191d;
  --primary-background: #080718;
  --toolbar-background: #080718;
  --border-radius: 3px;
  --disabled-opacity: 0.6;
  --highlight-background: #383838;
  --text-on-highlight: #ffffff;
  --hover-background: #383838;
  --focus-ring: #d0f1ee00;
  --form-background: #0a0922;
  --form-border: #2a2f34;
  --form-hover-border: #33393f;
  --form-focus-border: #33393f;
  --form-filled-background: #383838;
  --buttons-primary-hover: ##00cfe5;
  --buttons-secondary-background: #5a5858;
  --buttons-secondary-text: #ffffff;
  --buttons-success-background: #00cfe5;
  --buttons-success-text: #ffffff;
  --buttons-info-background: #3b82f6;
  --buttons-info-text: #ffffff;
  --buttons-warning-background: #f59e0b;
  --buttons-warning-text: #ffffff;
  --buttons-help-background: #a855f7;
  --buttons-help-text: #ffffff;
  --buttons-danger-background: #ef4444;
  --buttons-danger-text: #ffffff;
  --buttons-border: #383838;
  --messages-info: #eff6ff;
  --messages-success: #ecfcf5;
  --messages-warning: #fef3f7;
  --messages-error: #fef3f7;
  --10x-brand: #d1242a;
  --white-color: #fff;
}

* {
  box-sizing: border-box;
}
.p-component {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  font-weight: normal;
}
.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 200ms ease;
}
.p-disabled,
.p-component:disabled {
  opacity: var(--disabled-opacity);
}
.p-error {
  color: var(--error);
}
.p-surface-header {
  background: var(--surface-header);
}
.p-surface-content {
  background: var(--primary-background);
}
.p-surface-ground {
  background: var(--surface-ground);
}
.p-primary-border-color {
  border-bottom: var(--primary-color);
}
.p-primary-full-border-color {
  border: 2px solid var(--primary-color);
}
.p-background-primary-color {
  background: var(--primary-color);
}
.p-border-color {
  border-color: var(--form-border);
}
.font-primary {
  font-family: neuropol-x-light, sans-serif;
  font-weight: 700;
  font-style: normal;
}
.logo-font {
  font-family: neuropol-x-light, sans-serif;
  font-weight: 700;
  font-style: normal;
}
.p-link {
  color: var(--primary-color);
  text-decoration: none;
  text-decoration-color: var(--primary-color);
}
.p-link:hover {
  color: var(--primary-color-hover);
  text-decoration-color: var(--primary-color-hover);
}
.p-primary-text-color {
  color: var(--primary-color-text);
}
.p-text-primary {
  color: var(--text-color);
}
.p-text-secondary {
  color: var(--text-color-secondary);
}
.pi {
  font-size: 1rem;
}
.p-link {
  font-size: 1rem;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  border-radius: var(--border-radius);
}
.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-attribute-icon {
  box-shadow: 0 0 20px rgb(110 135 171 / 30%);
  background-color: var(--surface-header);
  -webkit-transition: background-color 500ms ease-in-out;
  -ms-transition: background-color 500ms ease-in-out;
  transition: background-color 500ms ease-in-out;
}
.p-form-border {
  border: 2px solid var(--form-border);
}
.p-primary-color-border {
  border: 2px solid var(--primary-color);
}
.field-radiobutton {
  margin: 0;
}

.color-circle {
  width: 25px;
  height: 25px;
  position: relative;
  border-radius: 50%;
}

.field-radiobutton:hover {
  box-shadow: 0px 0px 20px rgba(110, 135, 171, 0.55);
}

.field-radiobutton:active {
  box-shadow: 0px 0px 20px rgba(110, 135, 171, 1);
}

.field-radiobutton.selected {
  border: 2px solid var(--text-color);
}

/* .p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 200ms ease;
}
.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 200ms ease;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: rgba(0,0,0,0);
  }
  to {
    background-color: var(--maskbg);
  }
}
@keyframes p-component-overlay-leave-animation {
  from {
    background-color: var(--maskbg);
  }
  to {
    background-color: transparent;
  }
} */

.p-autocomplete .p-autocomplete-loader {
  right: 0.75rem;
}
.p-autocomplete-dropdown {
  background: var(--primary-color);
}
.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
  right: 3.75rem;
}
.p-autocomplete .p-autocomplete-multiple-container {
  padding: 0.375rem 0.75rem;
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: var(--form-hover-border);
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
  border-color: var(--form-hover-border);
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0.375rem 0;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  color: var(--text-color);
  padding: 0;
  margin: 0;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  background: var(--highlight-background);
  color: var(--text-on-highlight);
  border-radius: var(--border-radius);
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
  margin-left: 0.5rem;
}
.p-autocomplete.p-invalid.p-component > .p-inputtext {
  border-color: #f0a9a7;
}
.p-autocomplete-panel {
  background: var(--form-background);
  color: var(--text-color);
  border: 0 none;
  border-radius: var(--border-radius);
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.p-autocomplete-panel .p-autocomplete-items {
  padding: 0.25rem 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 0;
  padding: 0.75rem 1rem;
  border: 0 none;
  color: var(--text-color);
  background: transparent;
  transition: none;
  border-radius: 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  background: var(--surface-content);
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  color: var(--text-on-highlight);
  background: var(--highlight-background);
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: var(--text-color);
  background: var(--surface-content);
  font-weight: 600;
}
.p-calendar.p-invalid.p-component > .p-inputtext {
  border-color: #f0a9a7;
}
.p-datepicker {
  padding: 0.5rem;
  background: var(--surface-content);
  color: var(--text-color);
  border: 1px solid var(--form-border);
  border-radius: var(--border-radius);
}
.p-datepicker-year,
.p-datepicker-month {
  background: var(--surface-content);
}
.p-datepicker:not(.p-datepicker-inline) {
  background: var(--surface-content);
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: var(--surface-content);
}
.p-datepicker .p-datepicker-header {
  padding: 0.5rem;
  color: var(--text-color);
  background: var(--surface-content);
  font-weight: 600;
  margin: 0;
  border-bottom: 1px solid var(--surface-border);
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  color: var(--text-color-secondary);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #18181b;
  border-color: transparent;
  background: var(--hover-background);
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select {
  transition: none;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
  border-color: var(--form-hover-border);
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}
.p-datepicker table {
  font-size: 1rem;
  margin: 0.5rem 0;
}
.p-datepicker table th {
  padding: 0.5rem;
}
.p-datepicker table th > span {
  width: 2.5rem;
  height: 2.5rem;
}
.p-datepicker table td {
  padding: 0.5rem;
}
.p-datepicker table td > span {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transition: none;
  border: 1px solid transparent;
}
.p-datepicker table td > span.p-highlight {
  color: var(--text-on-highlight);
  background: var(--highlight-background);
}
.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-datepicker table td.p-datepicker-today > span {
  background: var(--form-border);
  color: var(--text-color);
  border-color: transparent;
}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: var(--text-on-highlight);
  background: var(--highlight-background);
}
.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid #f3f4f6;
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid #f3f4f6;
  padding: 0.5rem;
}
.p-datepicker .p-timepicker button {
  width: 2rem;
  height: 2rem;
  color: var(--text-color-secondary);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
}
.p-datepicker .p-timepicker button:enabled:hover {
  color: #18181b;
  border-color: transparent;
  background: var(--hover-background);
}
.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
  font-size: 1.25rem;
}
.p-datepicker .p-timepicker > div {
  padding: 0 0.5rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-datepicker .p-monthpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.5rem;
  transition: none;
  border-radius: var(--border-radius);
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: var(--text-on-highlight);
  background: var(--highlight-background);
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-right: 1px solid #f3f4f6;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
  border-right: 0 none;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: var(--hover-background);
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
  background: var(--hover-background);
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
@media screen and (max-width: 769px) {
  .p-datepicker table th,
  .p-datepicker table td {
    padding: 0;
  }
}
.p-cascadeselect {
  background: var(--form-background);
  border: 1px solid var(--form-border);
  transition: none;
  border-radius: var(--border-radius);
}
.p-cascadeselect:not(.p-disabled):hover {
  border-color: var(--form-hover-border);
}
.p-cascadeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
  border-color: var(--form-hover-border);
}
.p-cascadeselect .p-cascadeselect-label {
  background: transparent;
  border: 0 none;
  padding: 0.75rem 0.75rem;
}
.p-cascadeselect .p-cascadeselect-label.p-placeholder {
  color: var(--text-color-secondary);
}
.p-cascadeselect .p-cascadeselect-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-cascadeselect .p-cascadeselect-trigger {
  background: transparent;
  color: var(--text-color-secondary);
  width: 3rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.p-cascadeselect.p-invalid.p-component {
  border-color: #f0a9a7;
}
.p-cascadeselect-panel {
  background: var(--surface-content);
  color: var(--text-color);
  border: 0 none;
  border-radius: var(--border-radius);
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.p-cascadeselect-panel .p-cascadeselect-items {
  padding: 0.25rem 0;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
  margin: 0;
  border: 0 none;
  color: var(--text-color);
  background: transparent;
  transition: none;
  border-radius: 0;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
  padding: 0.75rem 1rem;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--focus-ring);
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
  color: var(--text-on-highlight);
  background: var(--highlight-background);
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #18181b;
  background: var(--hover-background);
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
  font-size: 0.875rem;
}
.p-input-filled .p-cascadeselect {
  background: var(--form-filled-background);
}
.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
  background-color: var(--form-filled-background);
}
.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
  background-color: var(--surface-content);
}
.p-checkbox {
  width: 16px;
  height: 16px;
}
.p-checkbox .p-checkbox-box {
  border: 2px solid var(--text-color);
  background: var(--form-background);
  width: 16px;
  height: 16px;
  color: var(--text-color);
  border-radius: var(--border-radius);
  transition: none;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: var(--primary-color-text);
  font-size: 10px;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--primary-color);
  background: var(--primary-color);
}
.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--primary-color);
  background: var(--primary-color);
  color: var(--primary-color-text);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: var(--form-hover-border);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
  border-color: var(--form-hover-border);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: var(--primary-color);
  background: var(--primary-color);
  color: var(--primary-color-text);
}
.p-checkbox.p-invalid > .p-checkbox-box {
  border-color: #f0a9a7;
}
.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: var(--form-filled-background);
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: var(--primary-color);
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background-color: var(--form-filled-background);
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: var(--primary-color);
}
.p-chips .p-chips-multiple-container {
  padding: 0.375rem 0.75rem;
}
.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: var(--form-hover-border);
}
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
  border-color: var(--form-hover-border);
}
.p-chips .p-chips-multiple-container .p-chips-token {
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  background: var(--highlight-background);
  color: var(--text-on-highlight);
  border-radius: var(--border-radius);
}
.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  margin-left: 0.5rem;
}
.p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 0.375rem 0;
}
.p-chips .p-chips-multiple-container .p-chips-input-token input {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  color: var(--text-color);
  padding: 0;
  margin: 0;
}
.p-chips.p-invalid.p-component > .p-inputtext {
  border-color: #f0a9a7;
}
.p-colorpicker-preview {
  width: 2rem;
  height: 2rem;
}
.p-colorpicker-panel {
  background: #27272a;
  border: 1px solid #18181b;
}
.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
  border-color: var(--surface-content);
}
.p-colorpicker-overlay-panel {
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.p-dropdown {
  background: var(--form-background);
  border: 1px solid var(--form-border);
  transition: none;
  border-radius: var(--border-radius);
}
.p-dropdown:not(.p-disabled):hover {
  border-color: var(--form-hover-border);
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
  border-color: var(--form-hover-border);
}
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 1.75rem;
}
.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: var(--text-color-secondary);
}
.p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: var(--text-color-secondary);
  width: 3rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.p-dropdown .p-dropdown-clear-icon {
  color: var(--text-color-secondary);
  right: 3rem;
}
.p-dropdown.p-invalid.p-component {
  border-color: #f0a9a7;
}
.p-dropdown-panel {
  background: var(--form-background);
  color: var(--text-color);
  border: 1px solid #060514;
  border-radius: var(--border-radius);
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.p-dropdown-panel .p-dropdown-header {
  padding: 0.5rem 0.75rem;
  border-bottom: 0 none;
  color: var(--text-color);
  /* background: var(--surface-header); */
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 1.75rem;
  margin-right: -1.75rem;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 0.75rem;
  color: var(--text-color-secondary);
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter {
  padding-right: 3.5rem;
  margin-right: -3.5rem;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter-clear-icon {
  right: 2.5rem;
}
.p-dropdown-panel .p-dropdown-items {
  padding: 0.25rem 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.75rem 1rem;
  border: 0 none;
  color: var(--text-color);
  background: transparent;
  transition: none;
  border-radius: 0;
  height: auto !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: var(--text-on-highlight);
  background: var(--highlight-background);
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  /* color: #18181b; */
  background: var(--primary-background);
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: var(--text-color);
  background: var(--surface-content);
  font-weight: 600;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 0.75rem 1rem;
  color: var(--text-color);
  background: transparent;
}
.p-input-filled .p-dropdown {
  background: var(--form-filled-background);
}
.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: var(--form-filled-background);
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: var(--surface-content);
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
  background-color: transparent;
}
.p-editor-container .p-editor-toolbar {
  background: var(--surface-header);
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid var(--surface-border);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: var(--text-color-secondary);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: var(--text-color-secondary);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  border: 0 none;
  color: var(--text-color-secondary);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
  color: var(--text-color);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
  stroke: var(--text-color);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
  fill: var(--text-color);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: var(--text-color);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: var(--text-color);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: var(--text-color);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: var(--surface-content);
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: var(--border-radius);
  padding: 0.25rem 0;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  color: var(--text-color);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  color: #18181b;
  background: var(--hover-background);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
  padding: 0.75rem 1rem;
}
.p-editor-container .p-editor-content {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-editor-container .p-editor-content.ql-snow {
  border: 1px solid var(--surface-border);
}
.p-editor-container .p-editor-content .ql-editor {
  background: var(--form-background);
  color: var(--text-color);
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: var(--text-color);
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: var(--text-color);
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: var(--text-color);
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: var(--primary-color);
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: var(--primary-color);
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: var(--primary-color);
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: var(--primary-color);
}
.p-editor-container.p-inputwrapper-filled.p-invalid {
  border: 1px solid #f0a9a7;
  border-radius: 4px;
}
.p-inputgroup-addon {
  background: var(--surface-header);
  color: var(--text-color-secondary);
  border-top: 1px solid var(--form-border);
  border-left: 1px solid var(--form-border);
  border-bottom: 1px solid var(--form-border);
  padding: 0.75rem 0.75rem;
  min-width: 3rem;
}

.p-inputgroup-addon:last-child {
  border-right: 1px solid var(--form-border);
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-inputwrapper > .p-inputtext,
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}

.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-inputwrapper > .p-inputtext + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
  border-left: 0 none;
}

.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-inputwrapper > .p-inputtext:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
  z-index: 1;
}

.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-inputwrapper > .p-inputtext:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
  z-index: 1;
}

.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  top: -0.5rem !important;
  background-color: var(--surface-section);
  padding: 2px 4px;
  margin-left: -4px;
  margin-top: 0;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-inputtext {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}
.p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 3rem;
}
.p-inputnumber.p-invalid.p-component > .p-inputtext {
  border-color: #f0a9a7;
}
.p-inputswitch {
  width: 3rem;
  height: 1.75rem;
}
.p-inputswitch .p-inputswitch-slider {
  background: var(--form-border);
  transition: none;
  border-radius: 30px;
}
.p-inputswitch .p-inputswitch-slider:before {
  background: var(--surface-content);
  width: 1.25rem;
  height: 1.25rem;
  left: 0.25rem;
  margin-top: -0.625rem;
  border-radius: 50%;
  transition-duration: 0.2s;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.25rem);
}
.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: var(--form-background);
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--primary-color);
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: var(--surface-content);
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: #ca3838;
}
.p-inputswitch.p-invalid {
  border-color: #f0a9a7;
}

.p-inputtext {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  padding: 0.5rem 0.9rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  /* font-size: 1rem; */
  color: var(--text-color);
  background: var(--form-background);
  /* padding: 0.75rem 0.75rem; */
  border: 1px solid var(--form-border);
  transition: none;
  appearance: none;
  border-radius: var(--border-radius);
}

.MuiFormControl-root.p-invalid > .MuiInputBase-root > fieldset {
  border-color: #f0a9a7;
}
.MuiFormControl-root.p-invalid > .MuiOutlinedInput-root:hover > fieldset {
  border-color: #f0a9a7 !important;
}

.MuiFormLabel-root {
  color: var(--text-color-secondary) !important;
  line-height: 0.5 !important;
}

.MuiOutlinedInput-root {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  color: var(--text-color) !important;
  background: var(--form-background);
  padding: 12px !important;
  transition: none;
  appearance: none;
  border-radius: var(--border-radius) !important;
}
.MuiOutlinedInput-root:hover > fieldset {
  border: 1px solid var(--primary-color) !important;
}
.MuiOutlinedInput-root.Mui-focused > fieldset {
  box-shadow: 0 0 0 1px var(--focus-ring);
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color);
}
.MuiOutlinedInput-input {
  font-size: 1rem;
  padding: 0 !important;
  min-height: 26px;
}

.MuiIconButton-root {
  color: var(--text-color) !important;
}

.MuiPickersYear-root {
  color: var(--text-color) !important;
}

.MuiPickersSlideTransition-transitionContainer > * {
  color: var(--text-color) !important;
}

.MuiPickersDay-day {
  color: var(--text-color) !important;
}
.MuiPickersDay-day:hover {
  background-color: var(--form-hover-border) !important;
  color: white !important;
}
.MuiPickersDay-daySelected {
  color: white !important;
  background-color: var(--primary-color) !important;
}

.MuiPickersCalendarHeader-iconButton {
  background: var(--form-background) !important;
}
.MuiPickersCalendarHeader-dayLabel {
  color: var(--text-color) !important;
}

.MuiPickersBasePicker-pickerView {
  background: var(--form-background) !important;
}

.MuiPickersToolbar-toolbar {
  background-color: var(--primary-color) !important;
}

.MuiTypography-colorPrimary {
  color: var(--primary-color) !important;
}

.MuiInputLabel-root {
  margin-left: -4px;
  margin-top: 0;
  box-sizing: border-box;
}
.MuiInputLabel-root.Mui-focused {
  background-color: var(--surface-section);
  padding: 6px !important;
  margin-top: 0;
  line-height: 1;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(12px, -6px) scale(0.75) !important;
  background-color: var(--surface-section);
  padding: 6px !important;
  margin-top: 0;
  line-height: 1;
}

.PrivateNotchedOutline-root-1,
.MuiOutlinedInput-notchedOutline {
  padding: 0px !important;
  border-color: var(--form-border) !important;
}
.PrivateNotchedOutline-legendLabelled-3 {
  z-index: 1 !important;
  width: 0 !important;
}

.p-inputtext:enabled:hover {
  border-color: var(--form-hover-border);
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
  border-color: var(--form-hover-border);
}
.p-inputtext.p-invalid.p-component {
  border-color: #f0a9a7;
}
.p-inputtext.p-inputtext-sm {
  font-size: 0.875rem;
  padding: 0.65625rem 0.65625rem;
}
.p-inputtext.p-inputtext-lg {
  font-size: 1.25rem;
  padding: 0.9375rem 0.9375rem;
}
.p-float-label > label {
  left: 0.75rem;
  color: var(--text-color-secondary);
  transition-duration: 0.2s;
}
.p-float-label > label.p-error {
  color: var(--error);
}
.p-input-icon-left > i:first-of-type,
.p-input-icon-left > svg:first-of-type,
.p-input-icon-left > .p-input-prefix {
  left: 0.75rem;
  color: var(--text-color-secondary);
}
.p-input-icon-left > .p-inputtext {
  padding-left: 2.5rem;
}
.p-input-icon-left.p-float-label > label {
  left: 2.5rem;
}

.p-float-label .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 0.25rem 1rem;
}

.p-input-filled .p-float-label .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  padding-top: 0;
  padding-bottom: 0;
}
.p-input-filled
  .p-float-label
  .p-autocomplete
  .p-autocomplete-multiple-container
  .p-autocomplete-token
  .p-autocomplete-token-icon {
  font-size: 75%;
}
.p-input-filled .p-float-label .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0;
}

.p-input-icon-right > i:last-of-type,
.p-input-icon-right > svg:last-of-type,
.p-input-icon-right > .p-input-suffix {
  right: 0.75rem;
  color: var(--text-color-secondary);
}
.p-input-icon-right > .p-inputtext {
  padding-right: 2.5rem;
}
::-webkit-input-placeholder {
  color: var(--text-color-secondary);
}
:-moz-placeholder {
  color: var(--text-color-secondary);
}
::-moz-placeholder {
  color: var(--text-color-secondary);
}
:-ms-input-placeholder {
  color: var(--text-color-secondary);
}
.p-input-filled .p-inputtext {
  background-color: var(--form-filled-background);
}
.p-input-filled .p-inputtext:enabled:hover {
  background-color: var(--form-filled-background);
}
.p-input-filled .p-inputtext:enabled:focus {
  background-color: var(--surface-content);
}
.p-inputtext-sm .p-inputtext {
  font-size: 0.875rem;
  padding: 0.65625rem 0.65625rem;
}
.p-inputtext-lg .p-inputtext {
  font-size: 1.25rem;
  padding: 0.9375rem 0.9375rem;
}
.p-listbox {
  background: var(--surface-content);
  color: var(--text-color);
  border: 1px solid var(--form-border);
  border-radius: var(--border-radius);
}
.p-listbox .p-listbox-header {
  padding: 0.5rem 0.75rem;
  border-bottom: 0 none;
  color: var(--text-color);
  background: var(--surface-header);
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-listbox .p-listbox-header .p-listbox-filter {
  padding-right: 1.75rem;
}
.p-listbox .p-listbox-header .p-listbox-filter-icon {
  right: 0.75rem;
  color: var(--text-color-secondary);
}
.p-listbox .p-listbox-list {
  padding: 0.25rem 0;
}
.p-listbox .p-listbox-list .p-listbox-item {
  margin: 0;
  padding: 0.75rem 1rem;
  border: 0 none;
  color: var(--text-color);
  transition: none;
  border-radius: 0;
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: var(--text-on-highlight);
  background: var(--highlight-background);
}
.p-listbox .p-listbox-list .p-listbox-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--focus-ring);
}
.p-listbox .p-listbox-list .p-listbox-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: var(--text-color);
  background: var(--surface-content);
  font-weight: 600;
}
.p-listbox .p-listbox-list .p-listbox-empty-message {
  padding: 0.75rem 1rem;
  color: var(--text-color);
  background: transparent;
}
.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: #18181b;
  background: var(--hover-background);
}
.p-listbox.p-invalid {
  border-color: #f0a9a7;
}
.p-mention-panel {
  background: var(--surface-content);
  color: var(--text-color);
  border: 0 none;
  border-radius: var(--border-radius);
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.p-mention-panel .p-mention-items {
  padding: 0.25rem 0;
}
.p-mention-panel .p-mention-items .p-mention-item {
  margin: 0;
  padding: 0.75rem 1rem;
  border: 0 none;
  color: var(--text-color);
  background: transparent;
  transition: none;
  border-radius: 0;
}
.p-mention-panel .p-mention-items .p-mention-item:hover {
  color: #18181b;
  background: var(--hover-background);
}
.p-mention-panel .p-mention-items .p-mention-item.p-highlight {
  color: var(--text-on-highlight);
  background: var(--highlight-background);
}
.p-multiselect {
  background: var(--form-background);
  border: 1px solid var(--form-border);
  transition: none;
  border-radius: var(--border-radius);
}
.p-multiselect:not(.p-disabled):hover {
  border-color: var(--form-hover-border);
}
.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
  border-color: var(--form-hover-border);
}
.p-multiselect.p-multiselect-clearable .p-multiselect-label {
  padding-right: 1.75rem;
}
.p-multiselect .p-multiselect-label {
  padding: 0.375rem 0.75rem;
  transition: none;
}
.p-multiselect .p-multiselect-label.p-placeholder {
  color: var(--text-color-secondary);
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.144rem 0.75rem;
  margin-right: 0.5rem;
  background: var(--highlight-background);
  color: var(--text-on-highlight);
  border-radius: var(--border-radius);
  font-size: 0.8125rem !important;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  margin-left: 0.5rem;
  font-size: 0.8125rem !important;
}
.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: var(--text-color-secondary);
  width: 3rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.p-multiselect .p-multiselect-clear-icon {
  color: var(--text-color-secondary);
  right: 3rem;
}
.p-multiselect.p-invalid.p-component {
  border-color: #f0a9a7;
}
.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.375rem 0.75rem;
}
.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label.p-multiselect-items-label {
  padding: 0.75rem 0.75rem;
}
.p-inputwrapper-filled.p-multiselect.p-multiselect-clearable .p-multiselect-label {
  padding-right: 1.75rem;
}
.p-multiselect-panel {
  /* background: var(--surface-content); */
  background: var(--form-background);
  color: var(--text-color);
  border: 0 none;
  border-radius: var(--border-radius);
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.p-multiselect-panel .p-multiselect-header {
  padding: 0.5rem 0.75rem;
  border-bottom: 0 none;
  color: var(--text-color);
  /* background: var(--surface-header); */
  background: var(--form-background);
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
  margin: 0 0.5rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding-right: 1.75rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 0.75rem;
  color: var(--text-color-secondary);
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  width: 2rem;
  height: 2rem;
  color: var(--text-color-secondary);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: #18181b;
  border-color: transparent;
  background: var(--hover-background);
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-multiselect-panel .p-multiselect-items {
  padding: 0.25rem 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.75rem 1rem;
  border: 0 none;
  color: var(--text-color);
  background: transparent;
  transition: none;
  border-radius: 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: var(--text-on-highlight);
  /* background: var(--highlight-background); */
  background: var(--primary-background);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #18181b;
  /* background: var(--hover-background); */
  background: var(--primary-background);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--focus-ring);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: var(--text-color);
  background: var(--surface-content);
  font-weight: 600;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  padding: 0.75rem 1rem;
  color: var(--text-color);
  background: transparent;
}
.p-input-filled .p-multiselect {
  background: var(--form-filled-background);
}
.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: var(--form-filled-background);
}
.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  background-color: var(--surface-content);
}
.p-password.p-invalid.p-component > .p-inputtext {
  border-color: #f0a9a7;
}
.p-password-panel {
  padding: 1.25rem;
  background: var(--surface-content);
  color: var(--text-color);
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: var(--border-radius);
}
.p-password-panel .p-password-meter {
  margin-bottom: 0.5rem;
  background: var(--surface-border);
}
.p-password-panel .p-password-meter .p-password-strength.weak {
  background: #ef4444;
}
.p-password-panel .p-password-meter .p-password-strength.medium {
  background: #f59e0b;
}
.p-password-panel .p-password-meter .p-password-strength.strong {
  background: #22c55e;
}
.p-radiobutton {
  width: 16px;
  height: 16px;
}
.p-radiobutton .p-radiobutton-box {
  border: 2px solid var(--form-border);
  background: var(--form-background);
  width: 16px;
  height: 16px;
  color: var(--text-color);
  border-radius: 50%;
  transition: none;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: var(--form-hover-border);
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
  border-color: var(--form-hover-border);
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 6px;
  height: 6px;
  transition-duration: 0.2s;
  background-color: var(--primary-color-text);
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--primary-color);
  background: var(--primary-color);
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--primary-color);
  background: var(--primary-color);
  color: var(--primary-color-text);
}
.p-radiobutton.p-invalid > .p-radiobutton-box {
  border-color: #f0a9a7;
}
.p-radiobutton:focus {
  outline: 0 none;
}
.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: var(--form-filled-background);
}
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: var(--form-filled-background);
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: var(--primary-color);
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: var(--primary-color);
}
.p-rating .p-rating-icon {
  color: var(--text-color);
  margin-left: 0.5rem;
  transition: none;
  font-size: 1.143rem;
}
.p-rating .p-rating-icon.p-rating-cancel {
  color: #ef4444;
}
.p-rating .p-rating-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-rating .p-rating-icon:first-child {
  margin-left: 0;
}
.p-rating .p-rating-icon.pi-star-fill {
  color: var(--primary-color);
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: var(--primary-color);
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
  color: #dc2626;
}
.p-selectbutton .p-button {
  background: var(--form-background);
  border: 1px solid var(--buttons-border);
  color: var(--text-color);
  transition: none;
}
.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
  color: var(--text-color-secondary);
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: var(--hover-background);
  border-color: var(--form-border);
  color: var(--text-color);
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: var(--text-color-secondary);
}
.p-selectbutton .p-button.p-highlight {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
  color: var(--primary-color-text);
}
.p-selectbutton .p-button.p-highlight:hover {
  background: var(--buttons-primary-hover);
  border-color: var(--buttons-primary-hover);
  color: var(--primary-color-text);
}
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
  color: var(--primary-color-text);
}
.p-selectbutton.p-invalid > .p-button {
  border-color: #f0a9a7;
}
.p-slider {
  background: var(--surface-border);
  border: 0 none;
  border-radius: var(--border-radius);
}
.p-slider.p-slider-horizontal {
  height: 0.286rem;
}
.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.5715rem;
  margin-left: -0.5715rem;
}
.p-slider.p-slider-vertical {
  width: 0.286rem;
}
.p-slider.p-slider-vertical .p-slider-handle {
  margin-left: -0.5715rem;
  margin-bottom: -0.5715rem;
}
.p-slider .p-slider-handle {
  height: 1.143rem;
  width: 1.143rem;
  background: var(--surface-content);
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  transition: none;
}
.p-slider .p-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-slider .p-slider-range {
  background: var(--primary-color);
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: var(--primary-color);
  border-color: var(--primary-color);
}
.p-treeselect {
  background: var(--form-background);
  border: 1px solid var(--form-border);
  transition: none;
  border-radius: var(--border-radius);
}
.p-treeselect:not(.p-disabled):hover {
  border-color: var(--form-hover-border);
}
.p-treeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
  border-color: var(--form-hover-border);
}
.p-treeselect .p-treeselect-label {
  padding: 0.75rem 0.75rem;
  transition: none;
}
.p-treeselect .p-treeselect-label.p-placeholder {
  color: var(--text-color-secondary);
}
.p-treeselect.p-treeselect-chip .p-treeselect-token {
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  background: var(--surface-border);
  color: var(--text-color);
  border-radius: 16px;
}
.p-treeselect .p-treeselect-trigger {
  background: transparent;
  color: var(--text-color-secondary);
  width: 3rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.p-treeselect.p-invalid.p-component {
  border-color: #f0a9a7;
}
.p-inputwrapper-filled.p-treeselect.p-treeselect-chip .p-treeselect-label {
  padding: 0.375rem 0.75rem;
}
.p-treeselect-panel {
  background: var(--surface-content);
  color: var(--text-color);
  border: 0 none;
  border-radius: var(--border-radius);
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.p-treeselect-panel .p-treeselect-header {
  padding: 0.5rem 0.75rem;
  border-bottom: 0 none;
  color: var(--text-color);
  background: var(--surface-header);
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
  margin-right: 0.5rem;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter {
  padding-right: 1.75rem;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon {
  right: 0.75rem;
  color: var(--text-color-secondary);
}
.p-treeselect-panel
  .p-treeselect-header
  .p-treeselect-filter-container.p-treeselect-clearable-filter
  .p-treeselect-filter {
  padding-right: 3.5rem;
}
.p-treeselect-panel
  .p-treeselect-header
  .p-treeselect-filter-container.p-treeselect-clearable-filter
  .p-treeselect-filter-clear-icon {
  right: 2.5rem;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close {
  width: 2rem;
  height: 2rem;
  color: var(--text-color-secondary);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
  color: #18181b;
  border-color: transparent;
  background: var(--hover-background);
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
  border: 0 none;
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
  padding: 0.75rem 1rem;
  color: var(--text-color);
  background: transparent;
}
.p-input-filled .p-treeselect {
  background: var(--form-filled-background);
}
.p-input-filled .p-treeselect:not(.p-disabled):hover {
  background-color: var(--form-filled-background);
}
.p-input-filled .p-treeselect:not(.p-disabled).p-focus {
  background-color: var(--surface-content);
}
.p-togglebutton.p-button {
  background: var(--form-background);
  border: 1px solid var(--form-border);
  color: var(--text-color);
  transition: none;
}
.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
  color: var(--text-color-secondary);
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background: var(--hover-background);
  border-color: var(--form-border);
  color: var(--text-color);
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: var(--text-color-secondary);
}
.p-togglebutton.p-button.p-highlight {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
  color: var(--primary-color-text);
}
.p-togglebutton.p-button.p-highlight:hover {
  background: var(--buttons-primary-hover);
  border-color: var(--buttons-primary-hover);
  color: var(--primary-color-text);
}
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
  color: var(--primary-color-text);
}
.p-togglebutton.p-button.p-invalid > .p-button {
  border-color: #f0a9a7;
}
.p-button {
  color: var(--primary-color-text);
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  padding: 0.75rem 1rem;
  font-size: 1rem;
  transition: none;
  border-radius: var(--border-radius);
}
.p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
  background: var(--buttons-primary-hover);
  color: var(--primary-color-text);
  border-color: var(--buttons-primary-hover);
}
.p-button:enabled:active,
.p-button:not(button):not(a):not(.p-disabled):active {
  background: var(--buttons-primary-hover);
  color: var(--primary-color-text);
  border-color: var(--buttons-primary-hover);
}
.p-button.p-button-outlined {
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:hover,
.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--primary-color) r g b / 4%);
  color: var(--primary-color);
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:active,
.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--primary-color) r g b / 16%);
  color: var(--primary-color);
  border: 1px solid;
}
.p-button.p-button-outlined.p-button-plain {
  color: var(--text-color-secondary);
  border-color: var(--text-color-secondary);
}
.p-button.p-button-outlined.p-button-plain:enabled:hover,
.p-button.p-button-outlined.p-button-plain:not(button):not(a):not(.p-disabled):hover {
  background: var(--hover-background);
  color: var(--text-color-secondary);
}
.p-button.p-button-outlined.p-button-plain:enabled:active,
.p-button.p-button-outlined.p-button-plain:not(button):not(a):not(.p-disabled):active {
  background: var(--surface-border);
  color: var(--text-color-secondary);
}
.p-button.p-button-text {
  background-color: transparent;
  color: var(--primary-color);
  border-color: transparent;
}
.p-button.p-button-text:enabled:hover,
.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--primary-color) r g b / 4%);
  color: var(--primary-color);
  border-color: transparent;
}
.p-button.p-button-text:enabled:active,
.p-button.p-button-text:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--primary-color) r g b / 16%);
  color: var(--primary-color);
  border-color: transparent;
}
.p-button.p-button-text.p-button-plain {
  color: var(--text-color-secondary);
}
.p-button.p-button-text.p-button-plain:enabled:hover,
.p-button.p-button-text.p-button-plain:not(button):not(a):not(.p-disabled):hover {
  background: var(--hover-background);
  color: var(--text-color-secondary);
}
.p-button.p-button-text.p-button-plain:enabled:active,
.p-button.p-button-text.p-button-plain:not(button):not(a):not(.p-disabled):active {
  background: var(--surface-border);
  color: var(--text-color-secondary);
}
.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring) !important;
}
.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}
.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}
.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}
.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}
.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  color: var(--primary-color);
  background-color: var(--primary-color-text);
}
.p-button.p-button-raised {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.p-button.p-button-rounded {
  border-radius: 2rem;
}
.p-button.p-button-icon-only {
  width: 3rem;
  padding: 0.75rem 0;
}
.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}
.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 3rem;
}
.p-button.p-button-sm {
  font-size: 0.875rem;
  padding: 0.65625rem 0.875rem;
}
.p-button.p-button-sm .p-button-icon {
  font-size: 0.875rem;
}
.p-button.p-button-lg {
  font-size: 1.25rem;
  padding: 0.9375rem 1.25rem;
}
.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
}
.p-button.p-button-loading-label-only.p-button-loading-left .p-button-label {
  margin-left: 0.5rem;
}
.p-button.p-button-loading-label-only.p-button-loading-right .p-button-label {
  margin-right: 0.5rem;
}
.p-button.p-button-loading-label-only.p-button-loading-top .p-button-label {
  margin-top: 0.5rem;
}
.p-button.p-button-loading-label-only.p-button-loading-bottom .p-button-label {
  margin-bottom: 0.5rem;
}
.p-button.p-button-loading-label-only .p-button-loading-icon {
  margin: 0;
}
.p-fluid .p-button {
  width: 100%;
}
.p-fluid .p-button-icon-only {
  width: 3rem;
}
.p-fluid .p-buttonset {
  display: flex;
}
.p-fluid .p-buttonset .p-button {
  flex: 1;
}
.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button,
.p-fileupload-choose.p-button-secondary {
  color: var(--buttons-secondary-text);
  background: var(--buttons-secondary-background);
  border: 1px solid var(--buttons-secondary-background);
}
.p-button.p-button-secondary:enabled:hover,
.p-button.p-button-secondary:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-secondary > .p-button:enabled:hover,
.p-buttonset.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary:enabled:hover,
.p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):hover {
  background: #475569;
  color: var(--buttons-secondary-text);
  border-color: #475569;
}
.p-button.p-button-secondary:enabled:focus,
.p-button.p-button-secondary:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-secondary > .p-button:enabled:focus,
.p-buttonset.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-secondary > .p-button:enabled:focus,
.p-splitbutton.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-secondary:enabled:focus,
.p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):focus {
  box-shadow: 0 0 0 0.2rem #3b3bff;
}
.p-button.p-button-secondary:enabled:active,
.p-button.p-button-secondary:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-secondary > .p-button:enabled:active,
.p-buttonset.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-secondary > .p-button:enabled:active,
.p-splitbutton.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary:enabled:active,
.p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):active {
  background: #475569;
  color: var(--buttons-secondary-text);
  border-color: #475569;
}
.p-button.p-button-secondary.p-button-outlined,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-secondary.p-button-outlined {
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.p-button.p-button-secondary.p-button-outlined:enabled:hover,
.p-button.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--buttons-secondary-background) r g b / 4%);
  color: var(--primary-color-hover);
  border: 1px solid var(--primary-color-hover);
}
.p-button.p-button-secondary.p-button-outlined:enabled:active,
.p-button.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--buttons-secondary-background) r g b / 16%);
  color: var(--buttons-secondary-background);
  border: 1px solid var(--primary-color-hover);
}
.p-button.p-button-secondary.p-button-text,
.p-buttonset.p-button-secondary > .p-button.p-button-text,
.p-splitbutton.p-button-secondary > .p-button.p-button-text,
.p-fileupload-choose.p-button-secondary.p-button-text {
  background-color: transparent;
  color: var(--buttons-secondary-background);
  border-color: transparent;
}
.p-button.p-button-secondary.p-button-text:enabled:hover,
.p-button.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--buttons-secondary-background) r g b / 4%);
  border-color: transparent;
  color: var(--buttons-secondary-background);
}
.p-button.p-button-secondary.p-button-text:enabled:active,
.p-button.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary.p-button-text:enabled:active,
.p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--buttons-secondary-background) r g b / 16%);
  border-color: transparent;
  color: var(--buttons-secondary-background);
}
.p-button.p-button-info,
.p-buttonset.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button,
.p-fileupload-choose.p-button-info {
  color: var(--buttons-info-text);
  background: var(--buttons-info-background);
  border: 1px solid var(--buttons-info-background);
}
.p-button.p-button-info:enabled:hover,
.p-button.p-button-info:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-info > .p-button:enabled:hover,
.p-buttonset.p-button-info > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-info > .p-button:enabled:hover,
.p-splitbutton.p-button-info > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-info:enabled:hover,
.p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):hover {
  background: #2563eb;
  color: var(--buttons-info-text);
  border-color: #2563eb;
}
.p-button.p-button-info:enabled:focus,
.p-button.p-button-info:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-info > .p-button:enabled:focus,
.p-buttonset.p-button-info > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-info > .p-button:enabled:focus,
.p-splitbutton.p-button-info > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-info:enabled:focus,
.p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):focus {
  box-shadow: 0 0 0 0.2rem #3c3cff;
}
.p-button.p-button-info:enabled:active,
.p-button.p-button-info:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-info > .p-button:enabled:active,
.p-buttonset.p-button-info > .p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-info > .p-button:enabled:active,
.p-splitbutton.p-button-info > .p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-info:enabled:active,
.p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):active {
  background: #2563eb;
  color: var(--buttons-info-text);
  border-color: #2563eb;
}
.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info > .p-button.p-button-outlined,
.p-splitbutton.p-button-info > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-info.p-button-outlined {
  background-color: transparent;
  color: var(--buttons-info-background);
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:hover,
.p-button.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-info.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--buttons-info-background) r g b / 4%);
  color: var(--buttons-info-background);
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:active,
.p-button.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-info.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--buttons-info-background) r g b / 16%);
  color: var(--buttons-info-background);
  border: 1px solid;
}
.p-button.p-button-info.p-button-text,
.p-buttonset.p-button-info > .p-button.p-button-text,
.p-splitbutton.p-button-info > .p-button.p-button-text,
.p-fileupload-choose.p-button-info.p-button-text {
  background-color: transparent;
  color: var(--buttons-info-background);
  border-color: transparent;
}
.p-button.p-button-info.p-button-text:enabled:hover,
.p-button.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-info.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--buttons-info-background) r g b / 4%);
  border-color: transparent;
  color: var(--buttons-info-background);
}
.p-button.p-button-info.p-button-text:enabled:active,
.p-button.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-info.p-button-text:enabled:active,
.p-fileupload-choose.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--buttons-info-background) r g b / 16%);
  border-color: transparent;
  color: var(--buttons-info-background);
}
.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button,
.p-fileupload-choose.p-button-success {
  color: var(--buttons-success-text);
  background: var(--buttons-success-background);
  border: 1px solid var(--buttons-success-background);
}
.p-button.p-button-success:enabled:hover,
.p-button.p-button-success:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-success > .p-button:enabled:hover,
.p-buttonset.p-button-success > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-success > .p-button:enabled:hover,
.p-splitbutton.p-button-success > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success:enabled:hover,
.p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):hover {
  background: var(--primary-color-hover);
  color: var(--buttons-success-text);
  border-color: var(--primary-color-hover);
}
.p-button.p-button-success:enabled:focus,
.p-button.p-button-success:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-success > .p-button:enabled:focus,
.p-buttonset.p-button-success > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-success > .p-button:enabled:focus,
.p-splitbutton.p-button-success > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-success:enabled:focus,
.p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):focus {
  box-shadow: 0 0 0 0.2rem #3b3bff;
}
.p-button.p-button-success:enabled:active,
.p-button.p-button-success:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-success > .p-button:enabled:active,
.p-buttonset.p-button-success > .p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-success > .p-button:enabled:active,
.p-splitbutton.p-button-success > .p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-success:enabled:active,
.p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):active {
  background: var(--primary-color-hover);
  color: var(--buttons-success-text);
  border-color: var(--primary-color-hover);
}
.p-button.p-button-success.p-button-outlined,
.p-buttonset.p-button-success > .p-button.p-button-outlined,
.p-splitbutton.p-button-success > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-success.p-button-outlined {
  background-color: transparent;
  color: var(--buttons-success-background);
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:hover,
.p-button.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--buttons-success-background) r g b / 4%);
  color: var(--buttons-success-background);
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:active,
.p-button.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-success.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--buttons-success-background) r g b / 16%);
  color: var(--buttons-success-background);
  border: 1px solid;
}
.p-button.p-button-success.p-button-text,
.p-buttonset.p-button-success > .p-button.p-button-text,
.p-splitbutton.p-button-success > .p-button.p-button-text,
.p-fileupload-choose.p-button-success.p-button-text {
  background-color: transparent;
  color: var(--buttons-success-background);
  border-color: transparent;
}
.p-button.p-button-success.p-button-text:enabled:hover,
.p-button.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--buttons-success-background) r g b / 4%);
  border-color: transparent;
  color: var(--buttons-success-background);
}
.p-button.p-button-success.p-button-text:enabled:active,
.p-button.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-success.p-button-text:enabled:active,
.p-fileupload-choose.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--buttons-success-background) r g b / 16%);
  border-color: transparent;
  color: var(--buttons-success-background);
}
.p-button.p-button-warning,
.p-buttonset.p-button-warning > .p-button,
.p-splitbutton.p-button-warning > .p-button,
.p-fileupload-choose.p-button-warning {
  color: var(--buttons-warning-text);
  background: var(--buttons-warning-background);
  border: 1px solid var(--buttons-warning-background);
}
.p-button.p-button-warning:enabled:hover,
.p-button.p-button-warning:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-warning > .p-button:enabled:hover,
.p-buttonset.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-warning > .p-button:enabled:hover,
.p-splitbutton.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning:enabled:hover,
.p-fileupload-choose.p-button-warning:not(button):not(a):not(.p-disabled):hover {
  background: #d97706;
  color: var(--buttons-warning-text);
  border-color: #d97706;
}
.p-button.p-button-warning:enabled:focus,
.p-button.p-button-warning:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-warning > .p-button:enabled:focus,
.p-buttonset.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-warning > .p-button:enabled:focus,
.p-splitbutton.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-warning:enabled:focus,
.p-fileupload-choose.p-button-warning:not(button):not(a):not(.p-disabled):focus {
  box-shadow: 0 0 0 0.2rem #3d3dff;
}
.p-button.p-button-warning:enabled:active,
.p-button.p-button-warning:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-warning > .p-button:enabled:active,
.p-buttonset.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-warning > .p-button:enabled:active,
.p-splitbutton.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-warning:enabled:active,
.p-fileupload-choose.p-button-warning:not(button):not(a):not(.p-disabled):active {
  background: #d97706;
  color: var(--buttons-warning-text);
  border-color: #d97706;
}
.p-button.p-button-warning.p-button-outlined,
.p-buttonset.p-button-warning > .p-button.p-button-outlined,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-warning.p-button-outlined {
  background-color: transparent;
  color: var(--buttons-warning-background);
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:hover,
.p-button.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--buttons-warning-background) r g b / 4%);
  color: var(--buttons-warning-background);
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:active,
.p-button.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-warning.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--buttons-warning-background) r g b / 16%);
  color: var(--buttons-warning-background);
  border: 1px solid;
}
.p-button.p-button-warning.p-button-text,
.p-buttonset.p-button-warning > .p-button.p-button-text,
.p-splitbutton.p-button-warning > .p-button.p-button-text,
.p-fileupload-choose.p-button-warning.p-button-text {
  background-color: transparent;
  color: var(--buttons-warning-background);
  border-color: transparent;
}
.p-button.p-button-warning.p-button-text:enabled:hover,
.p-button.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--buttons-warning-background) r g b / 4%);
  border-color: transparent;
  color: var(--buttons-warning-background);
}
.p-button.p-button-warning.p-button-text:enabled:active,
.p-button.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-warning.p-button-text:enabled:active,
.p-fileupload-choose.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--buttons-warning-background) r g b / 16%);
  border-color: transparent;
  color: var(--buttons-warning-background);
}
.p-button.p-button-help,
.p-buttonset.p-button-help > .p-button,
.p-splitbutton.p-button-help > .p-button,
.p-fileupload-choose.p-button-help {
  color: var(--buttons-help-text);
  background: var(--buttons-help-background);
  border: 1px solid var(--buttons-help-background);
}
.p-button.p-button-help:enabled:hover,
.p-button.p-button-help:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-help > .p-button:enabled:hover,
.p-buttonset.p-button-help > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-help > .p-button:enabled:hover,
.p-splitbutton.p-button-help > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-help:enabled:hover,
.p-fileupload-choose.p-button-help:not(button):not(a):not(.p-disabled):hover {
  background: #9333ea;
  color: var(--buttons-help-text);
  border-color: #9333ea;
}
.p-button.p-button-help:enabled:focus,
.p-button.p-button-help:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-help > .p-button:enabled:focus,
.p-buttonset.p-button-help > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-help > .p-button:enabled:focus,
.p-splitbutton.p-button-help > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-help:enabled:focus,
.p-fileupload-choose.p-button-help:not(button):not(a):not(.p-disabled):focus {
  box-shadow: 0 0 0 0.2rem #3e3eff;
}
.p-button.p-button-help:enabled:active,
.p-button.p-button-help:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-help > .p-button:enabled:active,
.p-buttonset.p-button-help > .p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-help > .p-button:enabled:active,
.p-splitbutton.p-button-help > .p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-help:enabled:active,
.p-fileupload-choose.p-button-help:not(button):not(a):not(.p-disabled):active {
  background: #9333ea;
  color: var(--buttons-help-text);
  border-color: #9333ea;
}
.p-button.p-button-help.p-button-outlined,
.p-buttonset.p-button-help > .p-button.p-button-outlined,
.p-splitbutton.p-button-help > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-help.p-button-outlined {
  background-color: transparent;
  color: var(--buttons-help-background);
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:hover,
.p-button.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-help.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--buttons-help-background) r g b / 4%);
  color: var(--buttons-help-background);
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:active,
.p-button.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-help.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--buttons-help-background) r g b / 16%);
  color: var(--buttons-help-background);
  border: 1px solid;
}
.p-button.p-button-help.p-button-text,
.p-buttonset.p-button-help > .p-button.p-button-text,
.p-splitbutton.p-button-help > .p-button.p-button-text,
.p-fileupload-choose.p-button-help.p-button-text {
  background-color: transparent;
  color: var(--buttons-help-background);
  border-color: transparent;
}
.p-button.p-button-help.p-button-text:enabled:hover,
.p-button.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-help.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--buttons-help-background) r g b / 4%);
  border-color: transparent;
  color: var(--buttons-help-background);
}
.p-button.p-button-help.p-button-text:enabled:active,
.p-button.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-help.p-button-text:enabled:active,
.p-fileupload-choose.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--buttons-help-background) r g b / 16%);
  border-color: transparent;
  color: var(--buttons-help-background);
}
.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button,
.p-fileupload-choose.p-button-danger {
  color: var(--buttons-danger-text);
  background: var(--buttons-danger-background);
  border: 1px solid var(--buttons-danger-background);
}
.p-button.p-button-danger:enabled:hover,
.p-button.p-button-danger:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-danger > .p-button:enabled:hover,
.p-buttonset.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger > .p-button:enabled:hover,
.p-splitbutton.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger:enabled:hover,
.p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):hover {
  background: #dc2626;
  color: var(--buttons-danger-text);
  border-color: #dc2626;
}
.p-button.p-button-danger:enabled:focus,
.p-button.p-button-danger:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-danger > .p-button:enabled:focus,
.p-buttonset.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-danger > .p-button:enabled:focus,
.p-splitbutton.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-danger:enabled:focus,
.p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):focus {
  box-shadow: 0 0 0 0.2rem #3f3fff;
}
.p-button.p-button-danger:enabled:active,
.p-button.p-button-danger:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-danger > .p-button:enabled:active,
.p-buttonset.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-danger > .p-button:enabled:active,
.p-splitbutton.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger:enabled:active,
.p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):active {
  background: #dc2626;
  color: var(--buttons-danger-text);
  border-color: #dc2626;
}
.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger > .p-button.p-button-outlined,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-danger.p-button-outlined {
  background-color: transparent;
  color: var(--buttons-danger-background);
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:hover,
.p-button.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--buttons-danger-background) r g b / 4%);
  color: var(--buttons-danger-background);
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:active,
.p-button.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--buttons-danger-background) r g b / 16%);
  color: var(--buttons-danger-background);
  border: 1px solid;
}
.p-button.p-button-danger.p-button-text,
.p-buttonset.p-button-danger > .p-button.p-button-text,
.p-splitbutton.p-button-danger > .p-button.p-button-text,
.p-fileupload-choose.p-button-danger.p-button-text {
  background-color: transparent;
  color: var(--buttons-danger-background);
  border-color: transparent;
}
.p-button.p-button-danger.p-button-text:enabled:hover,
.p-button.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--buttons-danger-background) r g b / 4%);
  border-color: transparent;
  color: var(--buttons-danger-background);
}
.p-button.p-button-danger.p-button-text:enabled:active,
.p-button.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger.p-button-text:enabled:active,
.p-fileupload-choose.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--buttons-danger-background) r g b / 16%);
  border-color: transparent;
  color: var(--buttons-danger-background);
}
.p-button.p-button-link {
  color: var(--primary-color);
  background: transparent;
  border: transparent;
}
.p-button.p-button-link:enabled:hover,
.p-button.p-button-link:not(button):not(a):not(.p-disabled):hover {
  background: transparent;
  color: var(--primary-color);
  border-color: transparent;
}
.p-button.p-button-link:enabled:hover .p-button-label,
.p-button.p-button-link:not(button):not(a):not(.p-disabled):hover .p-button-label {
  text-decoration: underline;
}
.p-button.p-button-link:enabled:focus,
.p-button.p-button-link:not(button):not(a):not(.p-disabled):focus {
  background: transparent;
  box-shadow: 0 0 0 0.2rem var(--focus-ring);
  border-color: transparent;
}
.p-button.p-button-link:enabled:active,
.p-button.p-button-link:not(button):not(a):not(.p-disabled):active {
  background: transparent;
  color: var(--primary-color);
  border-color: transparent;
}
.p-splitbutton {
  border-radius: var(--border-radius);
}
.p-splitbutton.p-button-outlined > .p-button {
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid;
}
.p-splitbutton.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--primary-color) r g b / 4%);
  color: var(--primary-color);
}
.p-splitbutton.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--primary-color) r g b / 16%);
  color: var(--primary-color);
}
.p-splitbutton.p-button-outlined.p-button-plain > .p-button {
  color: var(--text-color-secondary);
  border-color: var(--text-color-secondary);
}
.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:hover,
.p-splitbutton.p-button-outlined.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: var(--hover-background);
  color: var(--text-color-secondary);
}
.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:active,
.p-splitbutton.p-button-outlined.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):active {
  background: var(--surface-border);
  color: var(--text-color-secondary);
}
.p-splitbutton.p-button-text > .p-button {
  background-color: transparent;
  color: var(--primary-color);
  border-color: transparent;
}
.p-splitbutton.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--primary-color) r g b / 4%);
  color: var(--primary-color);
  border-color: transparent;
}
.p-splitbutton.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--primary-color) r g b / 16%);
  color: var(--primary-color);
  border-color: transparent;
}
.p-splitbutton.p-button-text.p-button-plain > .p-button {
  color: var(--text-color-secondary);
}
.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:hover,
.p-splitbutton.p-button-text.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: var(--hover-background);
  color: var(--text-color-secondary);
}
.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:active,
.p-splitbutton.p-button-text.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):active {
  background: var(--surface-border);
  color: var(--text-color-secondary);
}
.p-splitbutton.p-button-raised {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.p-splitbutton.p-button-rounded {
  border-radius: 2rem;
}
.p-splitbutton.p-button-rounded > .p-button {
  border-radius: 2rem;
}
.p-splitbutton.p-button-sm > .p-button {
  font-size: 0.875rem;
  padding: 0.65625rem 0.875rem;
}
.p-splitbutton.p-button-sm > .p-button .p-button-icon {
  font-size: 0.875rem;
}
.p-splitbutton.p-button-lg > .p-button {
  font-size: 1.25rem;
  padding: 0.9375rem 1.25rem;
}
.p-splitbutton.p-button-lg > .p-button .p-button-icon {
  font-size: 1.25rem;
}
.p-splitbutton.p-button-secondary.p-button-outlined > .p-button {
  background-color: transparent;
  color: var(--buttons-secondary-background);
  border: 1px solid;
}
.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--buttons-secondary-background) r g b / 4%);

  color: var(--buttons-secondary-background);
}
.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--buttons-secondary-background) r g b / 16%);
  color: var(--buttons-secondary-background);
}
.p-splitbutton.p-button-secondary.p-button-text > .p-button {
  background-color: transparent;
  color: var(--buttons-secondary-background);
  border-color: transparent;
}
.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--buttons-secondary-background) r g b / 4%);
  border-color: transparent;
  color: var(--buttons-secondary-background);
}
.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-secondary.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--buttons-secondary-background) r g b / 16%);
  border-color: transparent;
  color: var(--buttons-secondary-background);
}
.p-splitbutton.p-button-info.p-button-outlined > .p-button {
  background-color: transparent;
  color: var(--buttons-info-background);
  border: 1px solid;
}
.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-info.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--buttons-info-background) r g b / 4%);
  color: var(--buttons-info-background);
}
.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-info.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--buttons-info-background) r g b / 16%);
  color: var(--buttons-info-background);
}
.p-splitbutton.p-button-info.p-button-text > .p-button {
  background-color: transparent;
  color: var(--buttons-info-background);
  border-color: transparent;
}
.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-info.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--buttons-info-background) r g b / 4%);
  border-color: transparent;
  color: var(--buttons-info-background);
}
.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-info.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--buttons-info-background) r g b / 16%);
  border-color: transparent;
  color: var(--buttons-info-background);
}
.p-splitbutton.p-button-success.p-button-outlined > .p-button {
  background-color: transparent;
  color: var(--buttons-success-background);
  border: 1px solid;
}
.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-success.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--buttons-success-background) r g b / 4%);
  color: var(--buttons-success-background);
}
.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-success.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--buttons-success-background) r g b / 16%);
  color: var(--buttons-success-background);
}
.p-splitbutton.p-button-success.p-button-text > .p-button {
  background-color: transparent;
  color: var(--buttons-success-background);
  border-color: transparent;
}
.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-success.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--buttons-success-background) r g b / 4%);
  border-color: transparent;
  color: var(--buttons-success-background);
}
.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-success.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--buttons-success-background) r g b / 16%);
  border-color: transparent;
  color: var(--buttons-success-background);
}
.p-splitbutton.p-button-warning.p-button-outlined > .p-button {
  background-color: transparent;
  color: var(--buttons-warning-background);
  border: 1px solid;
}
.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-warning.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--buttons-warning-background) r g b / 4%);
  color: var(--buttons-warning-background);
}
.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-warning.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--buttons-warning-background) r g b / 16%);
  color: var(--buttons-warning-background);
}
.p-splitbutton.p-button-warning.p-button-text > .p-button {
  background-color: transparent;
  color: var(--buttons-warning-background);
  border-color: transparent;
}
.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-warning.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--buttons-warning-background) r g b / 4%);
  border-color: transparent;
  color: var(--buttons-warning-background);
}
.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-warning.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--buttons-warning-background) r g b / 16%);
  border-color: transparent;
  color: var(--buttons-warning-background);
}
.p-splitbutton.p-button-help.p-button-outlined > .p-button {
  background-color: transparent;
  color: var(--buttons-help-background);
  border: 1px solid;
}
.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-help.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--buttons-help-background) r g b / 4%);
  color: var(--buttons-help-background);
}
.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-help.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--buttons-help-background) r g b / 16%);
  color: var(--buttons-help-background);
}
.p-splitbutton.p-button-help.p-button-text > .p-button {
  background-color: transparent;
  color: var(--buttons-help-background);
  border-color: transparent;
}
.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-help.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--buttons-help-background) r g b / 4%);
  border-color: transparent;
  color: var(--buttons-help-background);
}
.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-help.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--buttons-help-background) r g b / 16%);
  border-color: transparent;
  color: var(--buttons-help-background);
}
.p-splitbutton.p-button-danger.p-button-outlined > .p-button {
  background-color: transparent;
  color: var(--buttons-danger-background);
  border: 1px solid;
}
.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-danger.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--buttons-danger-background) r g b / 4%);
  color: var(--buttons-danger-background);
}
.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-danger.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--buttons-danger-background) r g b / 16%);
  color: var(--buttons-danger-background);
}
.p-splitbutton.p-button-danger.p-button-text > .p-button {
  background-color: transparent;
  color: var(--buttons-danger-background);
  border-color: transparent;
}
.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-danger.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgb(from var(--buttons-danger-background) r g b / 4%);
  border-color: transparent;
  color: var(--buttons-danger-background);
}
.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-danger.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgb(from var(--buttons-danger-background) r g b / 16%);
  border-color: transparent;
  color: var(--buttons-danger-background);
}
.p-speeddial-button.p-button.p-button-icon-only {
  width: 4rem;
  height: 4rem;
}
.p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
  font-size: 1.3rem;
}
.p-speeddial-action {
  width: 3rem;
  height: 3rem;
  background: var(--text-color);
  color: #fff;
}
.p-speeddial-action:hover {
  background: #27272a;
  color: #fff;
}
.p-speeddial-direction-up .p-speeddial-item {
  margin: 0.25rem;
}
.p-speeddial-direction-up .p-speeddial-item:first-child {
  margin-bottom: 0.5rem;
}
.p-speeddial-direction-down .p-speeddial-item {
  margin: 0.25rem;
}
.p-speeddial-direction-down .p-speeddial-item:first-child {
  margin-top: 0.5rem;
}
.p-speeddial-direction-left .p-speeddial-item {
  margin: 0 0.25rem;
}
.p-speeddial-direction-left .p-speeddial-item:first-child {
  margin-right: 0.5rem;
}
.p-speeddial-direction-right .p-speeddial-item {
  margin: 0 0.25rem;
}
.p-speeddial-direction-right .p-speeddial-item:first-child {
  margin-left: 0.5rem;
}
.p-speeddial-circle .p-speeddial-item,
.p-speeddial-semi-circle .p-speeddial-item,
.p-speeddial-quarter-circle .p-speeddial-item {
  margin: 0;
}
.p-speeddial-circle .p-speeddial-item:first-child,
.p-speeddial-circle .p-speeddial-item:last-child,
.p-speeddial-semi-circle .p-speeddial-item:first-child,
.p-speeddial-semi-circle .p-speeddial-item:last-child,
.p-speeddial-quarter-circle .p-speeddial-item:first-child,
.p-speeddial-quarter-circle .p-speeddial-item:last-child {
  margin: 0;
}
.p-speeddial-mask {
  background-color: rgba(0, 0, 0, 0.4);
}
.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 2rem;
  height: 2rem;
  color: var(--text-color-secondary);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
  margin: 0.5rem;
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: #18181b;
  border-color: transparent;
  background: var(--hover-background);
}
.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-carousel .p-carousel-indicators {
  padding: 1rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: var(--hover-background);
  width: 2rem;
  height: 0.5rem;
  transition: none;
  border-radius: 0;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: var(--surface-border);
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: var(--highlight-background);
  color: var(--text-on-highlight);
}
.p-datatable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-datatable-header {
  background: var(--surface-header);
  color: var(--text-color);
  border: 1px solid var(--surface-border);
  border-width: 1px 0 1px 0;
  padding: 1.25rem 1.25rem;
  font-weight: 600;
}
.p-datatable .p-datatable-footer {
  background: var(--surface-header);
  color: var(--text-color);
  border: 1px solid var(--surface-border);
  border-width: 0 0 1px 0;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 0.75rem 1.5rem;
  border: 1px solid var(--hover-background);
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: var(--text-color);
  background: var(--primary-background);
  transition: none;
}
.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid var(--hover-background);
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: var(--text-color);
  background: var(--surface-header);
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: var(--text-color-secondary);
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: var(--text-on-highlight);
  background: var(--highlight-background);
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
  background: var(--hover-background);
  color: var(--text-color);
}
.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover .p-sortable-column-icon {
  color: var(--text-color-secondary);
}
.p-datatable .p-sortable-column.p-highlight {
  background: var(--surface-header);
  color: var(--primary-color);
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: var(--primary-color);
}
.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
  background: var(--hover-background);
  color: var(--primary-color);
}
.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
  color: var(--primary-color);
}
.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem var(--focus-ring);
  outline: 0 none;
}
.p-datatable .p-datatable-tbody > tr {
  background: var(--primary-background);
  color: var(--text-color);
  transition: none;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid var(--hover-background);
  border-width: 0 0 1px 0;
  padding: 1rem 1.5rem;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 2rem;
  height: 2rem;
  color: var(--text-color-secondary);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: #18181b;
  border-color: transparent;
  background: var(--hover-background);
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0.5rem;
}
.p-datatable .p-datatable-tbody > tr > td > .p-column-title {
  font-weight: 500;
}
.p-datatable .p-datatable-tbody > tr > td.p-highlight {
  background: var(--highlight-background);
  color: var(--text-on-highlight);
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: var(--highlight-background);
  color: var(--text-on-highlight);
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 var(--highlight-background);
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 var(--highlight-background);
}
.p-datatable.p-datatable-selectable
  .p-datatable-tbody
  > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  background: var(--hover-background);
  color: var(--text-color);
}
.p-datatable.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row:focus {
  outline: 0.15rem solid var(--focus-ring);
  outline-offset: -0.15rem;
}
.p-datatable.p-datatable-selectable-cell
  .p-datatable-tbody
  > tr.p-selectable-row
  > td.p-selectable-cell:not(.p-highlight):hover {
  background: var(--hover-background);
  color: var(--text-color);
}
.p-datatable.p-datatable-selectable-cell .p-datatable-tbody > tr.p-selectable-row > td.p-selectable-cell:focus {
  outline: 0.15rem solid var(--focus-ring);
  outline-offset: -0.15rem;
}
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  background: var(--hover-background);
  color: var(--text-color);
}
.p-datatable .p-column-resizer-helper {
  background: var(--primary-color);
}
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: var(--surface-header);
}
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead,
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot {
  background-color: var(--surface-header);
}
.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}
.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
  background: var(--surface-content);
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight {
  background: var(--highlight-background);
  color: var(--text-on-highlight);
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight .p-row-toggler {
  color: var(--text-on-highlight);
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight .p-row-toggler:hover {
  color: var(--text-on-highlight);
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd + .p-row-expanded {
  background: var(--surface-content);
}
.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.625rem 0.625rem;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.375rem 0.75rem;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.75rem;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.375rem 0.75rem;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.375rem 0.75rem;
}
.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 1.5625rem 1.5625rem;
}
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 0.9375rem 1.875rem;
}
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 1.25rem 1.875rem;
}
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 0.9375rem 1.875rem;
}
.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 0.9375rem 1.875rem;
}
.p-datatable-drag-selection-helper {
  background: rgb(from var(--primary-color) r g b / 16%);
}
.p-dataview .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-dataview-header {
  background: var(--surface-header);
  color: var(--text-color);
  border: 1px solid var(--hover-background);
  border-width: 1px 0 1px 0;
  padding: 1.25rem 1.25rem;
  font-weight: 600;
}
.p-dataview .p-dataview-content {
  background: var(--surface-header);
  color: var(--text-color);
  border: 0 none;
  padding: 0;
}
.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: solid var(--hover-background);
  border-width: 0 0 1px 0;
}
.p-dataview .p-dataview-footer {
  background: var(--surface-header);
  color: var(--text-color);
  border: 1px solid var(--hover-background);
  border-width: 0 0 1px 0;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.p-dataview .p-dataview-loading-icon {
  font-size: 2rem;
}
.p-datascroller .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datascroller .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datascroller .p-datascroller-header {
  background: var(--surface-header);
  color: var(--text-color);
  border: 1px solid var(--hover-background);
  border-width: 1px 0 1px 0;
  padding: 1.25rem 1.25rem;
  font-weight: 600;
}
.p-datascroller .p-datascroller-content {
  background: var(--surface-content);
  color: var(--text-color);
  border: 0 none;
  padding: 0;
}
.p-datascroller.p-datascroller-inline .p-datascroller-list > li {
  border: solid var(--hover-background);
  border-width: 0 0 1px 0;
}
.p-datascroller .p-datascroller-footer {
  background: var(--surface-header);
  color: var(--text-color);
  border: 1px solid var(--hover-background);
  border-width: 0 0 1px 0;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
  margin-left: 0.5rem;
}
.p-column-filter-menu-button {
  width: 2rem;
  height: 2rem;
  color: var(--text-color-secondary);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
}
.p-column-filter-menu-button:hover {
  color: #18181b;
  border-color: transparent;
  background: var(--hover-background);
}
.p-column-filter-menu-button.p-column-filter-menu-button-open,
.p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background: var(--hover-background);
  color: #18181b;
}
.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background: var(--highlight-background);
  color: var(--text-on-highlight);
}
.p-column-filter-menu-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-column-filter-clear-button {
  width: 2rem;
  height: 2rem;
  color: var(--text-color-secondary);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
}
.p-column-filter-clear-button:hover {
  color: #18181b;
  border-color: transparent;
  background: var(--hover-background);
}
.p-column-filter-clear-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-column-filter-overlay {
  background: var(--surface-content);
  color: var(--text-color);
  border: 0 none;
  border-radius: var(--border-radius);
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  min-width: 12.5rem;
}
.p-column-filter-overlay .p-column-filter-row-items {
  padding: 0.25rem 0;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
  margin: 0;
  padding: 0.75rem 1rem;
  border: 0 none;
  color: var(--text-color);
  background: transparent;
  transition: none;
  border-radius: 0;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
  color: var(--text-on-highlight);
  background: var(--highlight-background);
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
  color: #18181b;
  background: var(--hover-background);
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--focus-ring);
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
  border-top: 1px solid #f3f4f6;
  margin: 0.25rem 0;
}
.p-column-filter-overlay-menu .p-column-filter-operator {
  padding: 0.5rem 0.75rem;
  border-bottom: 0 none;
  color: var(--text-color);
  background: var(--surface-header);
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-column-filter-overlay-menu .p-column-filter-constraint {
  padding: 1.25rem;
  border-bottom: 1px solid #f3f4f6;
}
.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
  margin-bottom: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
  margin-top: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
  border-bottom: 0 none;
}
.p-column-filter-overlay-menu .p-column-filter-add-rule {
  padding: 0.75rem 1.25rem;
}
.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 1.25rem;
}
.fc.fc-unthemed .fc-view-container th {
  background: var(--surface-header);
  border: 1px solid var(--surface-border);
  color: var(--text-color);
}
.fc.fc-unthemed .fc-view-container td.fc-widget-content {
  border: 1px solid var(--surface-border);
  color: var(--text-color);
}
.fc.fc-unthemed .fc-view-container td.fc-head-container {
  border: 1px solid var(--surface-border);
}
.fc.fc-unthemed .fc-view-container .fc-view {
  background: var(--surface-content);
}
.fc.fc-unthemed .fc-view-container .fc-row {
  border-right: 1px solid var(--surface-border);
}
.fc.fc-unthemed .fc-view-container .fc-event {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: var(--primary-color-text);
}
.fc.fc-unthemed .fc-view-container .fc-divider {
  background: var(--surface-header);
  border: 1px solid var(--surface-border);
}
.fc.fc-unthemed .fc-toolbar .fc-button {
  color: var(--primary-color-text);
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  font-size: 1rem;
  transition: none;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:hover {
  background: var(--buttons-primary-hover);
  color: var(--primary-color-text);
  border-color: var(--buttons-primary-hover);
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active {
  background: var(--buttons-primary-hover);
  color: var(--primary-color-text);
  border-color: var(--buttons-primary-hover);
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: 'PrimeIcons' !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: '';
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: 'PrimeIcons' !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: '';
}
.fc.fc-unthemed .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: var(--form-background);
  border: 1px solid var(--form-border);
  color: var(--text-color);
  transition: none;
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: var(--hover-background);
  border-color: var(--form-border);
  color: var(--text-color);
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--primary-color-text);
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: var(--buttons-primary-hover);
  border-color: var(--buttons-primary-hover);
  color: var(--primary-color-text);
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:focus,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:focus,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
  z-index: 1;
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
  border-color: var(--surface-border);
}
.fc.fc-theme-standard .fc-view-harness th {
  background: var(--surface-header);
  border-color: var(--surface-border);
  color: var(--text-color);
}
.fc.fc-theme-standard .fc-view-harness td {
  color: var(--text-color);
  border-color: var(--surface-border);
}
.fc.fc-theme-standard .fc-view-harness .fc-view {
  background: var(--surface-content);
}
.fc.fc-theme-standard .fc-view-harness .fc-popover {
  background: none;
  border: 0 none;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header {
  border: 1px solid var(--surface-border);
  padding: 1.25rem;
  background: var(--surface-header);
  color: var(--text-color);
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-family: 'PrimeIcons' !important;
  font-size: 1rem;
  width: 2rem;
  height: 2rem;
  color: var(--text-color-secondary);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:before {
  content: '';
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:hover {
  color: #18181b;
  border-color: transparent;
  background: var(--hover-background);
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-body {
  padding: 1.25rem;
  border: 1px solid var(--surface-border);
  background: var(--surface-content);
  color: var(--text-color);
  border-top: 0 none;
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
  color: var(--primary-color-text);
  background: var(--primary-color);
  border-color: var(--primary-color);
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event .fc-event-main {
  color: var(--primary-color-text);
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event .fc-daygrid-event-dot {
  background: var(--primary-color);
  border-color: var(--primary-color);
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event:hover {
  background: var(--hover-background);
  color: #18181b;
}
.fc.fc-theme-standard .fc-view-harness .fc-cell-shaded {
  background: var(--surface-header);
}
.fc.fc-theme-standard .fc-toolbar .fc-button {
  color: var(--primary-color-text);
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  font-size: 1rem;
  transition: none;
  border-radius: var(--border-radius);
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
  background: var(--buttons-primary-hover);
  color: var(--primary-color-text);
  border-color: var(--buttons-primary-hover);
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active {
  background: var(--buttons-primary-hover);
  color: var(--primary-color-text);
  border-color: var(--buttons-primary-hover);
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
  opacity: var(--disabled-opacity);
  color: var(--primary-color-text);
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: 'PrimeIcons' !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: '';
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: 'PrimeIcons' !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: '';
}
.fc.fc-theme-standard .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: var(--form-background);
  border: 1px solid var(--form-border);
  color: var(--text-color);
  transition: none;
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: var(--hover-background);
  border-color: var(--form-border);
  color: var(--text-color);
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--primary-color-text);
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: var(--buttons-primary-hover);
  border-color: var(--buttons-primary-hover);
  color: var(--primary-color-text);
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:not(:disabled):focus,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:not(:disabled):focus,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:not(:disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
  z-index: 1;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.fc.fc-theme-standard .fc-highlight {
  color: var(--text-on-highlight);
  background: var(--highlight-background);
}
.p-orderlist .p-orderlist-controls {
  padding: 1.25rem;
}
.p-orderlist .p-orderlist-controls .p-button {
  margin-bottom: 0.5rem;
}
.p-orderlist .p-orderlist-header {
  background: var(--surface-header);
  color: var(--text-color);
  border: 1px solid var(--surface-border);
  padding: 1.25rem;
  font-weight: 700;
  border-bottom: 0 none;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-orderlist .p-orderlist-list {
  border: 1px solid var(--surface-border);
  background: var(--surface-content);
  color: var(--text-color);
  padding: 0.25rem 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 0.75rem 1rem;
  margin: 0;
  border: 0 none;
  color: var(--text-color);
  background: transparent;
  transition: transform 0.2s, none;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
  background: var(--hover-background);
  color: #18181b;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--focus-ring);
}
.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: var(--text-on-highlight);
  background: var(--highlight-background);
}
@media screen and (max-width: 769px) {
  .p-orderlist {
    flex-direction: column;
  }
  .p-orderlist .p-orderlist-controls {
    padding: 1.25rem;
    flex-direction: row;
  }
  .p-orderlist .p-orderlist-controls .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  .p-orderlist .p-orderlist-controls .p-button:last-child {
    margin-right: 0;
  }
}
.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
  background: var(--hover-background);
  color: #18181b;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight {
  background: var(--highlight-background);
  color: var(--text-on-highlight);
}
.p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
  color: #000;
}
.p-organizationchart .p-organizationchart-line-down {
  background: var(--surface-border);
}
.p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid var(--surface-border);
  border-color: var(--surface-border);
}
.p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid var(--surface-border);
  border-color: var(--surface-border);
}
.p-organizationchart .p-organizationchart-node-content {
  border: 1px solid var(--surface-border);
  background: var(--surface-content);
  color: var(--text-color);
  padding: 1.25rem;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  background: inherit;
  color: inherit;
  border-radius: 50%;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-paginator {
  background: var(--primary-background);
  color: var(--text-color-secondary);
  border: solid var(--surface-border);
  border-width: 0;
  padding: 0.75rem 1.5rem;
  border-radius: var(--border-radius);
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent;
  border: 1px solid var(--surface-border);
  color: var(--text-color-secondary);
  min-width: 3rem;
  height: 3rem;
  margin: 0 0 0 -1px;
  transition: none;
  border-radius: 0;
  padding: 0 1rem;
}
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: var(--hover-background);
  border-color: var(--form-border);
  color: var(--text-color);
}
.p-paginator .p-paginator-first {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.p-paginator .p-paginator-last {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  height: 3rem;
}
.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}
.p-paginator .p-paginator-page-input {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.p-paginator .p-paginator-page-input .p-inputtext {
  max-width: 3rem;
}
.p-paginator .p-paginator-current {
  background-color: transparent;
  border: 1px solid var(--form-border);
  color: var(--text-color-secondary);
  min-width: 3rem;
  height: 3rem;
  margin: 0 0 0 -1px;
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 1px solid var(--form-border);
  color: var(--text-color-secondary);
  min-width: 3rem;
  height: 3rem;
  margin: 0 0 0 -1px;
  transition: none;
  border-radius: 0;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: var(--highlight-background);
  border-color: var(--highlight-background);
  color: var(--text-on-highlight);
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: var(--hover-background);
  border-color: var(--form-border);
  color: var(--text-color);
}
.p-picklist .p-picklist-buttons {
  padding: 1.25rem;
}
.p-picklist .p-picklist-buttons .p-button {
  margin-bottom: 0.5rem;
}
.p-picklist .p-picklist-header {
  background: var(--surface-header);
  color: var(--text-color);
  border: 1px solid var(--surface-border);
  padding: 1.25rem;
  font-weight: 700;
  border-bottom: 0 none;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-picklist .p-picklist-list {
  border: 1px solid var(--surface-border);
  background: var(--surface-content);
  color: var(--text-color);
  padding: 0.25rem 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-picklist .p-picklist-list .p-picklist-item {
  padding: 0.75rem 1rem;
  margin: 0;
  border: 0 none;
  color: var(--text-color);
  background: transparent;
  transition: transform 0.2s, none;
}
.p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
  background: var(--hover-background);
  color: #18181b;
}
.p-picklist .p-picklist-list .p-picklist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--focus-ring);
}
.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: var(--text-on-highlight);
  background: var(--highlight-background);
}
@media screen and (max-width: 769px) {
  .p-picklist {
    flex-direction: column;
  }
  .p-picklist .p-picklist-buttons {
    padding: 1.25rem;
    flex-direction: row;
  }
  .p-picklist .p-picklist-buttons .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  .p-picklist .p-picklist-buttons .p-button:last-child {
    margin-right: 0;
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-right:before {
    content: '';
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-double-right:before {
    content: '';
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-left:before {
    content: '';
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-double-left:before {
    content: '';
  }
}
.p-tree {
  border: 1px solid var(--surface-border);
  background: var(--surface-content);
  color: var(--text-color);
  padding: 1.25rem;
  border-radius: var(--border-radius);
}
.p-tree .p-tree-container .p-treenode {
  padding: 0.143rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-radius: var(--border-radius);
  transition: none;
  padding: 0;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: var(--text-color-secondary);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  color: #18181b;
  border-color: transparent;
  background: var(--hover-background);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  margin-right: 0.5rem;
  color: var(--text-color-secondary);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: var(--text-color);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--focus-ring);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: var(--highlight-background);
  color: var(--text-on-highlight);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: var(--text-on-highlight);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
  color: var(--text-on-highlight);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: var(--hover-background);
  color: #18181b;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
  background: var(--hover-background);
  color: #18181b;
}
.p-tree .p-tree-filter-container {
  margin-bottom: 0.5rem;
}
.p-tree .p-tree-filter-container .p-tree-filter {
  width: 100%;
  padding-right: 1.75rem;
}
.p-tree .p-tree-filter-container .p-tree-filter-icon {
  right: 0.75rem;
  color: var(--text-color-secondary);
}
.p-tree .p-treenode-children {
  padding: 0 0 0 1rem;
}
.p-tree .p-tree-loading-icon {
  font-size: 2rem;
}
.p-tree .p-treenode-droppoint {
  height: 0.5rem;
}
.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
  background: var(--primary-color-text);
}
.p-treetable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-treetable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-treetable .p-treetable-header {
  background: var(--surface-header);
  color: var(--text-color);
  border: 1px solid var(--hover-background);
  border-width: 1px 0 1px 0;
  padding: 1.25rem 1.25rem;
  font-weight: 600;
}
.p-treetable .p-treetable-footer {
  background: var(--surface-header);
  color: var(--text-color);
  border: 1px solid var(--hover-background);
  border-width: 0 0 1px 0;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
}
.p-treetable .p-treetable-thead > tr > th {
  text-align: left;
  padding: 0.75rem 1.5rem;
  border: 1px solid var(--surface-border);
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: #6b7280;
  background: var(--surface-header);
  transition: none;
}
.p-treetable .p-treetable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid var(--hover-background);
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: var(--text-color);
  background: var(--surface-header);
}
.p-treetable .p-sortable-column {
  outline-color: var(--focus-ring);
}
.p-treetable .p-sortable-column .p-sortable-column-icon {
  color: var(--text-color-secondary);
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: var(--text-on-highlight);
  background: var(--highlight-background);
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover {
  background: var(--hover-background);
  color: var(--text-color);
}
.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: var(--text-color-secondary);
}
.p-treetable .p-sortable-column.p-highlight {
  background: var(--surface-header);
  color: var(--primary-color);
}
.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: var(--primary-color);
}
.p-treetable .p-treetable-tbody {
  background: var(--surface-header);
}
.p-treetable .p-treetable-tbody > tr {
  background: var(--surface-header);
  color: var(--text-color);
  transition: none;
}
.p-treetable .p-treetable-tbody > tr > td {
  text-align: left;
  border: 1px solid var(--surface-border);
  border-width: 0 0 1px 0;
  padding: 1rem 1.5rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
  width: 2rem;
  height: 2rem;
  color: var(--text-color-secondary);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
  margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
  color: var(--text-color);
  border-color: transparent;
  background: var(--hover-background);
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox {
  margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: var(--text-color);
}
.p-treetable .p-treetable-tbody > tr:focus {
  outline: 0.15rem solid var(--focus-ring);
  outline-offset: -0.15rem;
}
.p-treetable .p-treetable-tbody > tr.p-highlight {
  background: var(--highlight-background);
  color: var(--text-on-highlight);
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
  color: var(--text-on-highlight);
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
  color: var(--text-on-highlight);
}
.p-treetable.p-treetable-selectable .p-treetable-tbody > tr:not(.p-highlight):hover,
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
  background: var(--hover-background);
  color: var(--text-color);
}
.p-treetable.p-treetable-selectable .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler,
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
  color: var(--text-color);
}
.p-treetable .p-column-resizer-helper {
  background: var(--primary-color);
}
.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
  background: var(--surface-header);
}
.p-treetable .p-treetable-loading-icon {
  font-size: 2rem;
}
.p-treetable.p-treetable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-footer {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-top {
  border-width: 0 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-bottom {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-sm .p-treetable-header {
  padding: 1.09375rem 1.09375rem;
}
.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
  padding: 0.375rem 0.75rem;
}
.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
  padding: 0.5rem 0.75rem;
}
.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
  padding: 0.375rem 0.75rem;
}
.p-treetable.p-treetable-sm .p-treetable-footer {
  padding: 0.375rem 0.75rem;
}
.p-treetable.p-treetable-lg .p-treetable-header {
  padding: 1.5625rem 1.5625rem;
}
.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
  padding: 0.9375rem 1.875rem;
}
.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
  padding: 1.25rem 1.875rem;
}
.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
  padding: 0.9375rem 1.875rem;
}
.p-treetable.p-treetable-lg .p-treetable-footer {
  padding: 0.9375rem 1.875rem;
}
.p-timeline .p-timeline-event-marker {
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background-color: var(--surface-content);
}
.p-timeline .p-timeline-event-connector {
  background-color: var(--surface-border);
}
.p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.p-timeline.p-timeline-vertical .p-timeline-event-content {
  padding: 0 1rem;
}
.p-timeline.p-timeline-vertical .p-timeline-event-connector {
  width: 2px;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
  padding: 1rem 0;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
  height: 2px;
}
.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1.25rem;
  border-top: 1px solid var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
  color: var(--text-color);
  background: var(--surface-header);
  font-weight: 700;
  border-radius: var(--border-radius);
  transition: none;
}
.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: 0.5rem;
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: var(--hover-background);
  border-color: var(--primary-color-hover);
  color: var(--text-color);
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: var(--surface-header);
  border-color: var(--primary-color);
  color: var(--text-color);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-color: var(--primary-color-hover);
  background: var(--hover-background);
  color: var(--text-color);
}
.p-accordion .p-accordion-content {
  border-top: 1px solid var(--surface-border);
  border-bottom: 1px solid var(--primary-color);
  background: var(--surface-content);
  color: var(--text-color);
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-accordion .p-accordion-tab {
  margin-bottom: 0;
}
.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}
.p-accordion .p-accordion-tab .p-accordion-content {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion
  .p-accordion-tab:not(:first-child)
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link,
.p-accordion
  .p-accordion-tab:not(:first-child)
  .p-accordion-header:not(.p-disabled).p-highlight:hover
  .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-content {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.p-card {
  background: var(--primary-background);
  color: var(--text-color);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: var(--border-radius);
}
.p-card .p-card-body {
  padding: 1.25rem;
}
.p-card .p-card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.p-card .p-card-subtitle {
  font-weight: 400;
  margin-bottom: 0.5rem;
  color: var(--text-color-secondary);
}
.p-card .p-card-content {
  padding: 1.25rem 0;
}
.p-card .p-card-footer {
  padding: 1.25rem 0 0 0;
}
.p-fieldset {
  border: 1px solid var(--surface-border);
  background: var(--surface-content);
  color: var(--text-color);
  border-radius: var(--border-radius);
}
.p-fieldset .p-fieldset-legend {
  padding: 1.25rem;
  border: 1px solid var(--surface-border);
  color: var(--text-color);
  background: var(--surface-header);
  font-weight: 700;
  border-radius: var(--border-radius);
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
  padding: 0;
  transition: none;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  padding: 1.25rem;
  color: var(--text-color);
  border-radius: var(--border-radius);
  transition: none;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
  margin-right: 0.5rem;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
  background: var(--hover-background);
  border-color: var(--surface-border);
  color: #18181b;
}
.p-fieldset .p-fieldset-content {
  padding: 1.25rem;
}
.p-divider .p-divider-content {
  background-color: var(--surface-content);
}
.p-divider.p-divider-horizontal {
  margin: 1rem 0;
  padding: 0 1rem;
}
.p-divider.p-divider-horizontal:before {
  border-top: 1px var(--surface-border);
}
.p-divider.p-divider-horizontal .p-divider-content {
  padding: 0 0.5rem;
}
.p-divider.p-divider-vertical {
  margin: 0 1rem;
  padding: 1rem 0;
}
.p-divider.p-divider-vertical:before {
  border-left: 1px var(--surface-border);
}
.p-divider.p-divider-vertical .p-divider-content {
  padding: 0.5rem 0;
}
.p-panel .p-panel-header {
  border: 1px solid var(--surface-border);
  padding: 1.25rem;
  background: var(--surface-header);
  color: var(--text-color);
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-panel .p-panel-header .p-panel-title {
  font-weight: 700;
}
.p-panel .p-panel-header .p-panel-header-icon {
  width: 2rem;
  height: 2rem;
  color: var(--text-color-secondary);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
}
.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
  color: #18181b;
  border-color: transparent;
  background: var(--hover-background);
}
.p-panel .p-panel-header .p-panel-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-panel.p-panel-toggleable .p-panel-header {
  padding: 0.75rem 1.25rem;
}
.p-panel .p-panel-content {
  padding: 1.25rem;
  border: 1px solid var(--surface-border);
  background: var(--surface-content);
  color: var(--text-color);
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top: 0 none;
}
.p-panel .p-panel-footer {
  padding: 0.75rem 1.25rem;
  border: 1px solid var(--surface-border);
  background: var(--surface-content);
  color: var(--text-color);
  border-top: 0 none;
}
.p-splitter {
  border: 1px solid var(--surface-border);
  background: var(--surface-content);
  border-radius: var(--border-radius);
  color: var(--text-color);
}
.p-splitter .p-splitter-gutter {
  transition: none;
  background: var(--surface-header);
}
.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
  background: var(--surface-border);
}
.p-splitter .p-splitter-gutter-resizing {
  background: var(--surface-border);
}
.p-scrollpanel .p-scrollpanel-bar {
  background: var(--surface-header);
  border: 0 none;
}
.p-tabview .p-tabview-nav {
  border: 1px solid var(--surface-border);
  border-width: 0 0 2px 0;
}
.p-tabview .p-tabview-nav li {
  margin-right: 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid var(--surface-border);
  border-width: 0 0 2px 0;
  border-color: transparent transparent var(--surface-border) transparent;
  color: var(--text-color-secondary);
  padding: 1.25rem;
  font-weight: 700;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  transition: none;
  margin: 0 0 -2px 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 1px var(--focus-ring);
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: var(--surface-header);
  border-color: var(--text-color-secondary);
  color: var(--text-color-secondary);
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-nav-btn.p-link {
  background: var(--surface-header);
  color: var(--primary-color);
  width: 3rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 0;
}
.p-tabview .p-tabview-nav-btn.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 1px var(--focus-ring);
}
.p-tabview .p-tabview-panels {
  padding: 1.25rem;
  border: 0 none;
  color: var(--text-color);
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-toolbar {
  background: var(--toolbar-background);
  border: 1px solid var(--primary-color);
  padding: 1.25rem;
  border-radius: var(--border-radius);
}
.p-toolbar .p-toolbar-separator {
  margin: 0 0.5rem;
}
.p-confirm-popup {
  background: var(--surface-content);
  color: var(--text-color);
  border: 0 none;
  border-radius: var(--border-radius);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.p-confirm-popup .p-confirm-popup-content {
  padding: 1.25rem;
}
.p-confirm-popup .p-confirm-popup-footer {
  text-align: right;
  padding: 0 1rem 1rem 1rem;
}
.p-confirm-popup .p-confirm-popup-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-confirm-popup .p-confirm-popup-footer button:last-child {
  margin: 0;
}
.p-confirm-popup:after {
  border: solid transparent;
  border-color: rgba(0, 0, 12, 0);
  border-bottom-color: var(--surface-content);
}
.p-confirm-popup:before {
  border: solid transparent;
  border-color: rgba(0, 0, 12, 0);
  border-bottom-color: var(--surface-content);
}
.p-confirm-popup.p-confirm-popup-flipped:after {
  border-top-color: var(--surface-content);
}
.p-confirm-popup.p-confirm-popup-flipped:before {
  border-top-color: var(--surface-content);
}
.p-confirm-popup .p-confirm-popup-icon {
  font-size: 1.5rem;
}
.p-confirm-popup .p-confirm-popup-message {
  margin-left: 1rem;
}
.p-dialog {
  border-radius: var(--border-radius);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border: 0 none;
}
.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: var(--primary-background);
  color: var(--text-color);
  padding: 1.5rem;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 600;
  font-size: 1.5rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: var(--text-color-secondary);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
  margin-right: 0.5rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #18181b;
  border-color: transparent;
  background: var(--hover-background);
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}
.p-dialog .p-dialog-content {
  background: var(--primary-background);
  color: var(--text-color);
  padding: 0 1.5rem 1.5rem 1.5rem;
}
.p-dialog .p-dialog-footer {
  border-top: 0 none;
  background: var(--primary-background);
  color: var(--text-color);
  padding: 0 1.5rem 1.5rem 1.5rem;
  text-align: right;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

.p-overlaypanel {
  background: var(--surface-content);
  color: var(--text-color);
  border: 0 none;
  border-radius: var(--border-radius);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 1.25rem;
}
.p-overlaypanel .p-overlaypanel-close {
  background: var(--primary-color);
  color: var(--primary-color-text);
  width: 2rem;
  height: 2rem;
  transition: none;
  border-radius: 50%;
  position: absolute;
  top: -1rem;
  right: -1rem;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: var(--buttons-primary-hover);
  color: var(--primary-color-text);
}
.p-overlaypanel:after {
  border: solid transparent;
  border-color: rgba(0, 0, 12, 0);
  border-bottom-color: var(--surface-content);
}
.p-overlaypanel:before {
  border: solid transparent;
  border-color: rgba(0, 0, 12, 0);
  border-bottom-color: var(--surface-content);
}
.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: var(--surface-content);
}
.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: var(--surface-content);
}
.p-sidebar {
  background: var(--surface-header);
  color: var(--text-color);
  border: 0 none;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.p-sidebar .p-sidebar-header {
  padding: 1.25rem;
}
.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
  width: 2rem;
  height: 2rem;
  color: var(--text-color-secondary);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
}
.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
  color: #18181b;
  border-color: transparent;
  background: var(--hover-background);
}
.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-sidebar .p-sidebar-header + .p-sidebar-content {
  padding-top: 0;
}
.p-sidebar .p-sidebar-content {
  padding: 0rem;
}
.p-sidebar-content {
  background: var(--surface-header);
}

.p-tooltip .p-tooltip-text {
  background: var(--text-color);
  color: var(--surface-content);
  padding: 0.75rem 0.75rem;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: var(--border-radius);
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: var(--text-color);
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: var(--text-color);
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: var(--text-color);
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: var(--text-color);
}
.p-fileupload .p-fileupload-buttonbar {
  background: var(--surface-header);
  padding: 1.25rem;
  border: 1px solid var(--surface-border);
  color: var(--text-color);
  border-bottom: 0 none;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0.5rem;
}
.p-fileupload .p-fileupload-content {
  background: var(--surface-content);
  padding: 2rem 1rem;
  border: 1px solid var(--surface-border);
  color: var(--text-color);
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-fileupload .p-progressbar {
  height: 0.25rem;
}
.p-fileupload .p-fileupload-row > div {
  padding: 1rem 1.5rem;
}
.p-fileupload.p-fileupload-advanced .p-message {
  margin-top: 0;
}
.p-breadcrumb {
  background: var(--surface-content);
  border: 1px solid var(--surface-border);
  border-radius: var(--border-radius);
  padding: 1rem;
}
.p-breadcrumb ul li .p-menuitem-link {
  transition: none;
  border-radius: var(--border-radius);
}
.p-breadcrumb ul li .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: var(--text-color);
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: var(--text-color-secondary);
}
.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.5rem 0 0.5rem;
  color: var(--text-color);
}
.p-breadcrumb ul li:last-child .p-menuitem-text {
  color: var(--text-color);
}
.p-breadcrumb ul li:last-child .p-menuitem-icon {
  color: var(--text-color-secondary);
}
.p-contextmenu {
  padding: 0.25rem 0;
  background: var(--surface-content);
  color: var(--text-color);
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: var(--border-radius);
  width: 12.5rem;
}
.p-contextmenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: var(--text-color);
  border-radius: 0;
  transition: none;
  user-select: none;
}
.p-contextmenu .p-menuitem-link .p-menuitem-text {
  color: var(--text-color);
}
.p-contextmenu .p-menuitem-link .p-menuitem-icon {
  color: var(--text-color-secondary);
  margin-right: 0.5rem;
}
.p-contextmenu .p-menuitem-link .p-submenu-icon {
  color: var(--text-color-secondary);
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
  background: var(--hover-background);
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: var(--text-color-secondary);
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: var(--text-color-secondary);
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: var(--text-color-secondary);
}
.p-contextmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--focus-ring);
}
.p-contextmenu .p-submenu-list {
  padding: 0.25rem 0;
  background: var(--surface-content);
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: var(--border-radius);
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: var(--hover-background);
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: var(--text-color);
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: var(--text-color-secondary);
}
.p-contextmenu .p-menu-separator {
  border-top: 1px solid #f3f4f6;
  margin: 0.25rem 0;
}
.p-contextmenu .p-submenu-icon {
  font-size: 0.875rem;
}
.p-dock .p-dock-list {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;
}
.p-dock .p-dock-item {
  padding: 0.5rem;
}
.p-dock .p-dock-action {
  width: 4rem;
  height: 4rem;
}
.p-dock.p-dock-magnification.p-dock-top .p-dock-item-second-prev,
.p-dock.p-dock-magnification.p-dock-top .p-dock-item-second-next,
.p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-second-prev,
.p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-second-next {
  margin: 0 0.9rem;
}
.p-dock.p-dock-magnification.p-dock-top .p-dock-item-prev,
.p-dock.p-dock-magnification.p-dock-top .p-dock-item-next,
.p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-prev,
.p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-next {
  margin: 0 1.3rem;
}
.p-dock.p-dock-magnification.p-dock-top .p-dock-item-current,
.p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-current {
  margin: 0 1.5rem;
}
.p-dock.p-dock-magnification.p-dock-left .p-dock-item-second-prev,
.p-dock.p-dock-magnification.p-dock-left .p-dock-item-second-next,
.p-dock.p-dock-magnification.p-dock-right .p-dock-item-second-prev,
.p-dock.p-dock-magnification.p-dock-right .p-dock-item-second-next {
  margin: 0.9rem 0;
}
.p-dock.p-dock-magnification.p-dock-left .p-dock-item-prev,
.p-dock.p-dock-magnification.p-dock-left .p-dock-item-next,
.p-dock.p-dock-magnification.p-dock-right .p-dock-item-prev,
.p-dock.p-dock-magnification.p-dock-right .p-dock-item-next {
  margin: 1.3rem 0;
}
.p-dock.p-dock-magnification.p-dock-left .p-dock-item-current,
.p-dock.p-dock-magnification.p-dock-right .p-dock-item-current {
  margin: 1.5rem 0;
}
@media screen and (max-width: 960px) {
  .p-dock.p-dock-top .p-dock-list-container,
  .p-dock.p-dock-bottom .p-dock-list-container {
    overflow-x: auto;
    width: 100%;
  }
  .p-dock.p-dock-top .p-dock-list-container .p-dock-list,
  .p-dock.p-dock-bottom .p-dock-list-container .p-dock-list {
    margin: 0 auto;
  }
  .p-dock.p-dock-left .p-dock-list-container,
  .p-dock.p-dock-right .p-dock-list-container {
    overflow-y: auto;
    height: 100%;
  }
  .p-dock.p-dock-left .p-dock-list-container .p-dock-list,
  .p-dock.p-dock-right .p-dock-list-container .p-dock-list {
    margin: auto 0;
  }
  .p-dock .p-dock-list .p-dock-item {
    transform: none;
    margin: 0;
  }
}
.p-megamenu {
  padding: 1rem;
  background: var(--surface-header);
  color: var(--text-color);
  border: 1px solid var(--surface-border);
  border-radius: var(--border-radius);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: var(--text-color);
  border-radius: var(--border-radius);
  transition: none;
  user-select: none;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: var(--text-color);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: var(--text-color-secondary);
  margin-right: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: var(--text-color-secondary);
  margin-left: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: var(--hover-background);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: var(--text-color-secondary);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: var(--text-color-secondary);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: var(--text-color-secondary);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--focus-ring);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: var(--hover-background);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-text {
  color: var(--text-color);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-icon {
  color: var(--text-color-secondary);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-submenu-icon {
  color: var(--text-color-secondary);
}
.p-megamenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: var(--text-color);
  border-radius: 0;
  transition: none;
  user-select: none;
}
.p-megamenu .p-menuitem-link .p-menuitem-text {
  color: var(--text-color);
}
.p-megamenu .p-menuitem-link .p-menuitem-icon {
  color: var(--text-color-secondary);
  margin-right: 0.5rem;
}
.p-megamenu .p-menuitem-link .p-submenu-icon {
  color: var(--text-color-secondary);
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
  background: var(--hover-background);
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: var(--text-color-secondary);
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: var(--text-color-secondary);
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: var(--text-color-secondary);
}
.p-megamenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--focus-ring);
}
.p-megamenu .p-megamenu-panel {
  background: var(--surface-content);
  color: var(--text-color);
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.p-megamenu .p-megamenu-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: var(--text-color);
  background: var(--surface-content);
  font-weight: 600;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-megamenu .p-megamenu-submenu {
  padding: 0.25rem 0;
  width: 12.5rem;
}
.p-megamenu .p-megamenu-submenu .p-menu-separator {
  border-top: 1px solid #f3f4f6;
  margin: 0.25rem 0;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: var(--hover-background);
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: var(--text-color);
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: var(--text-color-secondary);
}
.p-megamenu.p-megamenu-vertical {
  width: 12.5rem;
  padding: 0.25rem 0;
}
.p-menu {
  padding: 0.25rem 0;
  background: var(--surface-header);
  color: var(--text-color);
  border: 1px solid var(--primary-color);
  border-radius: 0;
  width: 12.5rem;
}
.p-menu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: var(--text-color);
  border-radius: 0;
  transition: none;
  user-select: none;
}
.p-menu .p-menuitem-link .p-menuitem-text {
  color: var(--text-color);
}
.p-menu .p-menuitem-link .p-menuitem-icon {
  color: var(--text-color-secondary);
  margin-right: 0.5rem;
}
.p-menu .p-menuitem-link .p-submenu-icon {
  color: var(--text-color-secondary);
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: var(--hover-background);
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: var(--text-color);
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: var(--text-color-secondary);
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: var(--text-color-secondary);
}
.p-menu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--focus-ring);
}
.p-menu.p-menu-overlay {
  background: var(--surface-content);
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.p-menu .p-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: var(--text-color);
  background: var(--surface-header);
  font-weight: 600;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-menu .p-menu-separator {
  border-top: 1px solid var(--primary-color);
  margin: 0.25rem 0;
}
.p-menubar {
  padding: 1rem;
  background: var(--surface-header);
  color: var(--text-color);
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius);
}
.p-menubar .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: var(--text-color);
  border-radius: 0;
  transition: none;
  user-select: none;
}
.p-menubar .p-menuitem-link .p-menuitem-text {
  color: var(--text-color);
}
.p-menubar .p-menuitem-link .p-menuitem-icon {
  color: var(--text-color-secondary);
  margin-right: 0.5rem;
}
.p-menubar .p-menuitem-link .p-submenu-icon {
  color: var(--text-color-secondary);
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover {
  background: var(--hover-background);
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #18181b;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: var(--text-color-secondary);
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: var(--text-color-secondary);
}
.p-menubar .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--focus-ring);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: var(--text-color);
  border-radius: var(--border-radius);
  transition: none;
  user-select: none;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: var(--text-color);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: var(--text-color-secondary);
  margin-right: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: var(--text-color-secondary);
  margin-left: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: var(--hover-background);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #18181b;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: var(--text-color-secondary);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: var(--text-color-secondary);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--focus-ring);
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: var(--hover-background);
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-text {
  color: var(--text-color);
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-icon {
  color: var(--text-color-secondary);
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-submenu-icon {
  color: var(--text-color-secondary);
}
.p-menubar .p-submenu-list {
  padding: 0.25rem 0;
  background: var(--surface-content);
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  width: 12.5rem;
}
.p-menubar .p-submenu-list .p-menu-separator {
  border-top: 1px solid #f3f4f6;
  margin: 0.25rem 0;
}
.p-menubar .p-submenu-list .p-submenu-icon {
  font-size: 0.875rem;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: var(--hover-background);
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: var(--text-color);
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: var(--text-color-secondary);
}
@media screen and (max-width: 960px) {
  .p-menubar {
    position: relative;
  }
  .p-menubar .p-menubar-button {
    display: flex;
    width: 2rem;
    height: 2rem;
    color: var(--text-color-secondary);
    border-radius: 50%;
    transition: none;
  }
  .p-menubar .p-menubar-button:hover {
    color: var(--text-color-secondary);
    background: var(--hover-background);
  }
  .p-menubar .p-menubar-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px var(--focus-ring);
  }
  .p-menubar .p-menubar-root-list {
    position: absolute;
    display: none;
    padding: 0.25rem 0;
    background: var(--surface-content);
    border: 0 none;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    width: 100%;
  }
  .p-menubar .p-menubar-root-list .p-menu-separator {
    border-top: 1px solid #f3f4f6;
    margin: 0.25rem 0;
  }
  .p-menubar .p-menubar-root-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: var(--text-color);
    border-radius: 0;
    transition: none;
    user-select: none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: var(--text-color);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: var(--text-color-secondary);
    margin-right: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: var(--text-color-secondary);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: var(--hover-background);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #18181b;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: var(--text-color-secondary);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: var(--text-color-secondary);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem var(--focus-ring);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    margin-left: auto;
    transition: transform 0.2s;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none;
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform 0.2s;
    transform: rotate(90deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg);
  }
  .p-menubar .p-menubar-root-list .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list ul li a {
    padding-left: 2.25rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li a {
    padding-left: 3.75rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li a {
    padding-left: 5.25rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
    padding-left: 6.75rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
    padding-left: 8.25rem;
  }
  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
  }
}
.p-panelmenu .p-panelmenu-header > a {
  padding: 1.25rem;
  border: 1px solid var(--surface-border);
  color: var(--text-color);
  background: var(--surface-header);
  font-weight: 700;
  border-radius: var(--border-radius);
  transition: none;
}
.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  background: var(--hover-background);
  border-color: var(--surface-border);
  color: var(--text-color);
}
.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight > a {
  background: var(--surface-header);
  border-color: var(--surface-border);
  color: var(--text-color);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
  border-color: var(--surface-border);
  background: var(--hover-background);
  color: var(--text-color);
}
.p-panelmenu .p-panelmenu-content {
  padding: 0.25rem 0;
  border: 1px solid var(--surface-border);
  background: var(--surface-content);
  color: var(--text-color);
  margin-bottom: 0;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: var(--text-color);
  border-radius: 0;
  transition: none;
  user-select: none;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: var(--text-color);
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: var(--text-color-secondary);
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: var(--text-color-secondary);
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background: var(--hover-background);
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #18181b;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: var(--text-color-secondary);
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: var(--text-color-secondary);
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--focus-ring);
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 1rem;
}
.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
  border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover > a,
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover > a {
  border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) > a {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-slidemenu {
  padding: 0.25rem 0;
  background: var(--surface-content);
  color: var(--text-color);
  border: 1px solid var(--surface-border);
  border-radius: var(--border-radius);
  width: 12.5rem;
}
.p-slidemenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: var(--text-color);
  border-radius: 0;
  transition: none;
  user-select: none;
}
.p-slidemenu .p-menuitem-link .p-menuitem-text {
  color: var(--text-color);
}
.p-slidemenu .p-menuitem-link .p-menuitem-icon {
  color: var(--text-color-secondary);
  margin-right: 0.5rem;
}
.p-slidemenu .p-menuitem-link .p-submenu-icon {
  color: var(--text-color-secondary);
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
  background: var(--hover-background);
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #18181b;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: var(--text-color-secondary);
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: var(--text-color-secondary);
}
.p-slidemenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--focus-ring);
}
.p-slidemenu.p-slidemenu-overlay {
  background: var(--surface-content);
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.p-slidemenu .p-slidemenu-list {
  padding: 0.25rem 0;
  background: var(--surface-content);
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
  background: var(--hover-background);
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
  color: var(--text-color);
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon,
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
  color: var(--text-color-secondary);
}
.p-slidemenu .p-slidemenu-separator {
  border-top: 1px solid #f3f4f6;
  margin: 0.25rem 0;
}
.p-slidemenu .p-slidemenu-icon {
  font-size: 0.875rem;
}
.p-slidemenu .p-slidemenu-backward {
  padding: 0.75rem 1rem;
  color: var(--text-color);
}
.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: none;
  border-radius: var(--border-radius);
  background: var(--surface-content);
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: var(--text-color);
  border: 1px solid var(--hover-background);
  background: var(--surface-content);
  min-width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1.143rem;
  z-index: 1;
  border-radius: 50%;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: var(--text-color-secondary);
}
.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: var(--highlight-background);
  color: var(--text-on-highlight);
}
.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
  color: var(--text-color);
}
.p-steps .p-steps-item:before {
  content: ' ';
  border-top: 1px solid #f3f4f6;
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}
.p-tabmenu .p-tabmenu-nav {
  background: var(--surface-content);
  border: 1px solid var(--surface-border);
  border-width: 0 0 2px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: solid var(--surface-border);
  border-width: 0 0 2px 0;
  border-color: transparent transparent #00000d transparent;
  background: var(--surface-content);
  color: var(--text-color-secondary);
  padding: 1.25rem;
  font-weight: 700;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  transition: none;
  margin: 0 0 -2px 0;
  height: calc(100% + 2px);
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 1px var(--focus-ring);
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  background: var(--surface-content);
  border-color: var(--text-color-secondary);
  color: var(--text-color-secondary);
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: var(--surface-content);
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.p-tieredmenu {
  padding: 0.25rem 0;
  background: var(--surface-content);
  color: var(--text-color);
  border: 1px solid var(--surface-border);
  border-radius: var(--border-radius);
  width: 12.5rem;
}
.p-tieredmenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: var(--text-color);
  border-radius: 0;
  transition: none;
  user-select: none;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  color: var(--text-color);
}
.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
  color: var(--text-color-secondary);
  margin-right: 0.5rem;
}
.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  color: var(--text-color-secondary);
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
  background: var(--hover-background);
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #18181b;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: var(--text-color-secondary);
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: var(--text-color-secondary);
}
.p-tieredmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--focus-ring);
}
.p-tieredmenu.p-tieredmenu-overlay {
  background: var(--surface-content);
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.p-tieredmenu .p-submenu-list {
  padding: 0.25rem 0;
  background: var(--surface-content);
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: var(--hover-background);
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: var(--text-color);
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: var(--text-color-secondary);
}
.p-tieredmenu .p-menu-separator {
  border-top: 1px solid #f3f4f6;
  margin: 0.25rem 0;
}
.p-tieredmenu .p-submenu-icon {
  font-size: 0.875rem;
}
.p-inline-message {
  padding: 0.75rem 0.75rem;
  margin: 0;
  border-radius: var(--border-radius);
}
.p-inline-message.p-inline-message-info {
  background: var(--messages-info);
  border: none;
  border-width: 0px;
  color: #2563eb;
}
.p-inline-message.p-inline-message-info .p-inline-message-icon {
  color: #2563eb;
}
.p-inline-message.p-inline-message-success {
  background: var(--messages-success);
  border: none;
  border-width: 0px;
  color: #059669;
}
.p-inline-message.p-inline-message-success .p-inline-message-icon {
  color: #059669;
}
.p-inline-message.p-inline-message-warn {
  background: var(--messages-warning);
  border: none;
  border-width: 0px;
  color: #d97706;
}
.p-inline-message.p-inline-message-warn .p-inline-message-icon {
  color: #d97706;
}
.p-inline-message.p-inline-message-error {
  background: #002;
  border: none;
  border-width: 0px;
  color: #dc2626;
}
.p-inline-message.p-inline-message-error .p-inline-message-icon {
  color: #dc2626;
}
.p-inline-message .p-inline-message-icon {
  font-size: 1rem;
  margin-right: 0.5rem;
}
.p-inline-message .p-inline-message-text {
  font-size: 1rem;
}
.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
  margin-right: 0;
}
.p-message {
  margin: 1rem 0;
  border-radius: var(--border-radius);
}
.p-message .p-message-wrapper {
  padding: 1.25rem 1.75rem;
}
.p-message .p-message-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: none;
}
.p-message .p-message-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-message .p-message-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-message.p-message-info {
  background: var(--messages-info);
  border: none;
  border-width: 0 0 0 6px;
  color: #2563eb;
}
.p-message.p-message-info .p-message-icon {
  color: #2563eb;
}
.p-message.p-message-info .p-message-close {
  color: #2563eb;
}
.p-message.p-message-success {
  background: var(--messages-success);
  border: none;
  border-width: 0 0 0 6px;
  color: #059669;
}
.p-message.p-message-success .p-message-icon {
  color: #059669;
}
.p-message.p-message-success .p-message-close {
  color: #059669;
}
.p-message.p-message-warn {
  background: var(--messages-warning);
  border: none;
  border-width: 0 0 0 6px;
  color: #d97706;
}
.p-message.p-message-warn .p-message-icon {
  color: #d97706;
}
.p-message.p-message-warn .p-message-close {
  color: #d97706;
}
.p-message.p-message-error {
  background: #002;
  border: none;
  border-width: 0 0 0 6px;
  color: #dc2626;
}
.p-message.p-message-error .p-message-icon {
  color: #dc2626;
}
.p-message.p-message-error .p-message-close {
  color: #dc2626;
}
.p-message .p-message-text {
  font-size: 1rem;
  font-weight: 500;
}
.p-message .p-message-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}
.p-toast {
  opacity: 0.95;
}
.p-toast .p-toast-message {
  /* margin: 0 0 1rem 0; */
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: var(--border-radius);
}
.p-toast .p-toast-message .p-toast-message-content {
  padding: 1rem;
  /* border-width: 0 0 0 6px; */
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 0 0 1rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: 2rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 700;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0.5rem 0 0 0;
}
.p-toast .p-toast-message .p-toast-icon-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: none;
}
.p-toast .p-toast-message .p-toast-icon-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-toast .p-toast-message .p-toast-icon-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-toast .p-toast-message.p-toast-message-info {
  background: var(--messages-info);
  border-left: 6px solid #2563eb;
  color: #2563eb;
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: #2563eb;
}
.p-toast .p-toast-message.p-toast-message-success {
  background: var(--messages-success);
  border-left: 6px solid #22c55e;
  color: #22c55e;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #22c55e;
}
.p-toast .p-toast-message.p-toast-message-warn {
  background: var(--messages-warning);
  border-left: 6px solid #d97706;
  color: #d97706;
}
.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: #d97706;
}
.p-toast .p-toast-message.p-toast-message-error {
  background: var(--messages-error);
  border-left: 6px solid #dc2626;
  color: #dc2626;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: #dc2626;
}
.p-galleria .p-galleria-close {
  margin: 0.5rem;
  background: transparent;
  color: var(--surface-header);
  width: 4rem;
  height: 4rem;
  transition: none;
  border-radius: 50%;
}
.p-galleria .p-galleria-close .p-galleria-close-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-close:hover {
  background: rgba(255, 255, 255, 0.1);
  color: var(--surface-header);
}
.p-galleria .p-galleria-item-nav {
  background: transparent;
  color: var(--surface-header);
  width: 4rem;
  height: 4rem;
  transition: none;
  border-radius: var(--border-radius);
  margin: 0 0.5rem;
}
.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
  background: rgba(255, 255, 255, 0.1);
  color: var(--surface-header);
}
.p-galleria .p-galleria-caption {
  background: rgba(0, 0, 0, 0.5);
  color: var(--surface-header);
  padding: 1rem;
}
.p-galleria .p-galleria-indicators {
  padding: 1rem;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button {
  background-color: var(--hover-background);
  width: 1rem;
  height: 1rem;
  transition: none;
  border-radius: 50%;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
  background: var(--surface-border);
}
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: var(--highlight-background);
  color: var(--text-on-highlight);
}
.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator,
.p-galleria.p-galleria-indicators-top .p-galleria-indicator {
  margin-right: 0.5rem;
}
.p-galleria.p-galleria-indicators-left .p-galleria-indicator,
.p-galleria.p-galleria-indicators-right .p-galleria-indicator {
  margin-bottom: 0.5rem;
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
  background: rgba(0, 0, 0, 0.5);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
  background: rgba(255, 255, 255, 0.4);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
  background: rgba(255, 255, 255, 0.6);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: var(--highlight-background);
  color: var(--text-on-highlight);
}
.p-galleria .p-galleria-thumbnail-container {
  background: rgba(0, 0, 0, 0.9);
  padding: 1rem 0.25rem;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  margin: 0.5rem;
  background-color: transparent;
  color: var(--surface-header);
  width: 2rem;
  height: 2rem;
  transition: none;
  border-radius: 50%;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
  background: rgba(255, 255, 255, 0.1);
  color: var(--surface-header);
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content {
  transition: none;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-galleria-mask {
  --maskbg: rgba(0, 0, 0, 0.9);
}
.p-image-mask {
  --maskbg: rgba(0, 0, 0, 0.9);
}
.p-image-preview-indicator {
  background-color: transparent;
  color: #f8f9fa;
  transition: none;
}
.p-image-preview-container:hover > .p-image-preview-indicator {
  background-color: rgba(0, 0, 0, 0.5);
}
.p-image-toolbar {
  padding: 1rem;
}
.p-image-action.p-link {
  color: #f8f9fa;
  background-color: transparent;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transition: none;
  margin-right: 0.5rem;
}
.p-image-action.p-link:last-child {
  margin-right: 0;
}
.p-image-action.p-link:hover {
  color: #f8f9fa;
  background-color: rgba(255, 255, 255, 0.1);
}
.p-image-action.p-link i {
  font-size: 1.5rem;
}
.p-avatar {
  background-color: var(--primary-color);
  border-radius: 100%;
}
.p-avatar.p-avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
}
.p-avatar.p-avatar-lg .p-avatar-icon {
  font-size: 1.5rem;
}
.p-avatar.p-avatar-xl {
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
}
.p-avatar.p-avatar-xl .p-avatar-icon {
  font-size: 2rem;
}
.p-avatar-group .p-avatar {
  border: 2px solid var(--surface-content);
}
.p-chip {
  background-color: var(--surface-border);
  color: var(--text-color);
  border-radius: 16px;
  padding: 0 0.75rem;
}
.p-chip .p-chip-text {
  line-height: 1.5;
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}
.p-chip .p-chip-icon {
  margin-right: 0.5rem;
}
.p-chip img {
  width: 2.25rem;
  height: 2.25rem;
  margin-left: -0.75rem;
  margin-right: 0.5rem;
}
.p-chip .p-chip-remove-icon {
  border-radius: var(--border-radius);
  transition: none;
  margin-left: 0.5rem;
}
.p-chip .p-chip-remove-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-scrolltop {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  transition: none;
}
.p-scrolltop.p-link {
  background: rgba(0, 0, 0, 0.7);
}
.p-scrolltop.p-link:hover {
  background: rgba(0, 0, 0, 0.8);
}
.p-scrolltop .p-scrolltop-icon {
  font-size: 1.5rem;
  color: var(--surface-header);
}
.p-skeleton {
  background-color: var(--hover-background);
  border-radius: var(--border-radius);
}
.p-skeleton:after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}
.p-tag {
  background: var(--primary-color);
  color: var(--primary-color-text);
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 5px;
}
.p-tag.p-tag-success {
  background-color: var(--buttons-success-background);
  color: var(--buttons-success-text);
}
.p-tag.p-tag-info {
  background-color: var(--buttons-info-background);
  color: var(--buttons-info-text);
}
.p-tag.p-tag-warning {
  background-color: var(--buttons-warning-background);
  color: var(--buttons-warning-text);
}
.p-tag.p-tag-danger {
  background-color: var(--buttons-danger-background);
  color: var(--buttons-danger-text);
}
.p-tag .p-tag-icon {
  margin-right: 0.25rem;
  font-size: 0.75rem;
}
.p-inplace .p-inplace-display {
  padding: 0.75rem 0.75rem;
  border-radius: var(--border-radius);
  transition: none;
}
.p-inplace .p-inplace-display:not(.p-disabled):hover {
  background: var(--hover-background);
  color: #18181b;
}
.p-inplace .p-inplace-display:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring);
}
.p-progressbar {
  border: 0 none;
  height: 1.5rem;
  background: var(--surface-border);
  border-radius: var(--border-radius);
}
.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: var(--primary-color);
}
.p-progressbar .p-progressbar-label {
  color: var(--text-color);
  line-height: 1.5rem;
}
.p-terminal {
  background: var(--surface-content);
  color: var(--text-color);
  border: 1px solid var(--surface-border);
  padding: 1.25rem;
}
.p-terminal .p-terminal-input {
  font-size: 1rem;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.p-badge {
  background: var(--primary-color);
  color: var(--primary-color-text);
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}
.p-badge.p-badge-secondary {
  background-color: var(--buttons-secondary-background);
  color: var(--buttons-secondary-text);
}
.p-badge.p-badge-success {
  background-color: var(--buttons-success-background);
  color: var(--buttons-success-text);
}
.p-badge.p-badge-info {
  background-color: var(--buttons-info-background);
  color: var(--buttons-info-text);
}
.p-badge.p-badge-warning {
  background-color: var(--buttons-warning-background);
  color: var(--buttons-warning-text);
}
.p-badge.p-badge-danger {
  background-color: var(--buttons-danger-background);
  color: var(--buttons-danger-text);
}
.p-badge.p-badge-lg {
  font-size: 1.125rem;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}
.p-badge.p-badge-xl {
  font-size: 1.5rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}
.p-tag {
  background: var(--primary-color);
  color: var(--primary-color-text);
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
}
.p-tag.p-tag-success {
  background-color: var(--buttons-success-background);
  color: var(--buttons-success-text);
}
.p-tag.p-tag-info {
  background-color: var(--buttons-info-background);
  color: var(--buttons-info-text);
}
.p-tag.p-tag-warning {
  background-color: var(--buttons-warning-background);
  color: var(--buttons-warning-text);
}
.p-tag.p-tag-danger {
  background-color: var(--buttons-danger-background);
  color: var(--buttons-danger-text);
}
.p-virtualscroller-loading-icon {
  font-size: 2rem;
}
.p-inputtext,
.p-togglebutton,
.p-selectbutton,
.p-inputgroup {
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.p-inputgroup .p-inputtext,
.p-inputgroup .p-togglebutton,
.p-inputgroup .p-selectbutton {
  box-shadow: none;
}
.p-inputtext.p-invalid.p-component:enabled:focus {
  box-shadow: 0 0 0 1px #f0a9a7;
}
.p-highlight {
  font-weight: 600;
}
.p-button-label {
  font-weight: 500;
}
.p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: 0 0 0 2px var(--focus-ring);
}
.p-paginator .p-paginator-pages .p-paginator-page {
  margin-left: -1px;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  border-color: var(--primary-color);
  margin-right: 1px;
}
.p-paginator .p-paginator-current {
  border: 0 none;
}
.p-button:focus {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #6366f1, 0 1px 2px 0 rgba(0, 0, 0, 0);
}
.p-button.p-button-secondary:enabled:focus {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #000013, 0 1px 2px 0 rgba(0, 0, 0, 0);
}
.p-button.p-button-success:enabled:focus {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #000015, 0 1px 2px 0 rgba(0, 0, 0, 0);
}
.p-button.p-button-info:enabled:focus {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #000017, 0 1px 2px 0 rgba(0, 0, 0, 0);
}
.p-button.p-button-warning:enabled:focus {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #000019, 0 1px 2px 0 rgba(0, 0, 0, 0);
}
.p-button.p-button-help:enabled:focus {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #00001b, 0 1px 2px 0 rgba(0, 0, 0, 0);
}
.p-button.p-button-danger:enabled:focus {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #00001d, 0 1px 2px 0 rgba(0, 0, 0, 0);
}
.p-checkbox .p-checkbox-box {
  border-radius: 0.25rem;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #6366f1, 0 1px 2px 0 rgba(0, 0, 0, 0);
}
.p-radiobutton:not(.p-radiobutton-disabled) .p-radiobutton-box.p-focus {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #6366f1, 0 1px 2px 0 rgba(0, 0, 0, 0);
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 var(--primary-color);
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 var(--primary-color);
}
.table-page > .p-toolbar {
  background: var(--surface-header) !important;
  border: none;
  border-bottom: 1px solid var(--hover-background);
  border-radius: 0;
}

.icon-grid {
  grid-template-columns: repeat(auto-fit, minmax(120px, 2fr));
}

.score-card-border-primary {
  border: var(--primary-color) 1px solid;
  border-radius: 10px;
}
.p-datatable-tbody div.cell-data {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 350px;
}
