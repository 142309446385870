.progress_bar {
  min-width: 30%;
  height: 1rem;

  .p-progressbar-label {
    line-height: 1rem;
    font-size: 0.8rem;
    font-weight: 300;
  }
}

.message {
  background: transparent !important;
  padding: 5px 0px !important;

  &.no_icon {
    .p-inline-message-icon {
      display: none !important;
    }
  }
}

.display {
  overflow: hidden;
  background: var(--form-background);
  border: 1px dashed var(--form-border);
  border-radius: var(--border-radius);
  position: relative;
  display: inline-block;
  height: 200px;
  width: fit-content;

  .p-image {
    display: inline-block;
    height: 100%;
    width: auto;
    min-width: 100px;

    img {
      height: 100%;
    }
  }

  button {
    right: 0;
  }
}
