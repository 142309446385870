body > iframe {
  display: none;
}
.custom-spinner-wrapper {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.color-red {
  color: var(--error);
}

.text-red {
  color: var(--error) !important;
}

.p-inputtext-label {
  font-size: 0.8125rem;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
  display: inline-block;
  opacity: 0.8;
}
.MuiInputBase-root {
  border: 1px solid var(--form-border);
}

.MuiInputBase-root:focus,
.MuiInputBase-root:hover {
  border-color: var(--form-hover-border);
}

.MuiInputBase-root fieldset {
  display: none;
}
.MuiInputBase-input {
  font-size: 0.8125rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  min-height: auto !important;
  color: var(--text-color) !important;
}

.field-checkbox {
  margin: 12px 0;
}
.p-checkbox-box {
  box-shadow: none !important;
}
.field-checkbox .p-inputtext-label {
  margin-bottom: 0;
}

.p-autocomplete-dropdown.p-button-icon-only {
  padding: 0.45rem 0 !important;
}

.p-autocomplete .p-button {
  position: absolute;
  right: 0;
  border: 0;
  background: transparent !important;
}

.main-content {
  overflow: hidden;
}

.page-title-box {
  margin: -32px -1.5rem 0.2rem -1.5rem;
}

.vertical-navs-step .nav .nav-link {
  &.active {
    border-color: #e80074 !important;
  }
  &.disabled {
    color: #878a99 !important;

    .step-icon {
      color: inherit;
    }
  }
}

div.node-foreign-object-div {
  position: fixed;
}
::-webkit-scrollbar {
  width: 4px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--text-color-secondary);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active {
  background-color: var(--text-color-secondary);
}
::-webkit-scrollbar-track {
  background-color: var(--surface-content);
  border-radius: 4px;
}
.p-dialog-mask {
  z-index: 1100 !important;
}

.line-clamp-2 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.image-page .dark {
  background: var(--primary-background);
  height: 100vh;
}
.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 200px;
}

.btn-size {
  padding: 0.5rem 0.9rem !important;
  font-size: 0.8125rem !important;
  border-radius: 0.25rem !important;
}
.myorg-content {
  margin-top: 40px;
  height: 80vh;
  background-color: #fff;
  overflow: auto;
  font-size: 16px;
  h1,
  h2,
  h3,
  h4,
  h5,
  td,
  th,
  span,
  li,
  a,
  .card,
  p {
    color: #000 !important;
    background-color: #fff;
  }
  a {
    text-decoration: underline;
  }
}
.company-category {
  display: flex;
  gap: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  justify-content: space-between;
  cursor: pointer;
  span.active {
    border-bottom: 2px solid #e80074;
  }
}

.rdt_Table {
  background: var(--vz-card-bg);

  .rdt_TableHeadRow {
    color: var(--vz-body-color);
    background: var(--vz-light);
  }

  .rdt_TableRow {
    color: var(--vz-body-color);
    background: var(--vz-card-bg);

    &:not(:last-of-type) {
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-bottom-color: var(--vz-border-color);
    }

    &:hover {
      color: var(--vz-body-color);
      background: var(--vz-light);
      outline-color: transparent;
    }
  }
}

.rdt_Pagination {
  color: var(--vz-body-color) !important;
  background-color: var(--vz-card-bg) !important;
  border-top-color: var(--vz-border-color) !important;

  svg {
    fill: var(--vz-body-color);
  }
}
