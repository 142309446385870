.entity-list-card {
  // margin-top:70px;
  padding-bottom: 1.5rem;

  h3 {
    margin-top: 20px;
    font-size: 28px;
    font-weight: bold;
  }
  .entities {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
  .p-listbox {
    background: transparent !important;
    border-color: transparent !important;

    .p-listbox-header {
      background: transparent !important;
      padding: 0.5rem 0rem !important;
    }

    .p-listbox-list {
      columns: 1;

      .p-listbox-item-group {
        background: var(--surface-header) !important;
        color: var(--primary-color-text);
        opacity: 0.6;
      }

      .p-listbox-item {
        padding: 0rem 1rem;
        width: 50%;
        display: inline-block;
        button {
          color: var(--text-color);
        }
        &:hover {
          background: transparent !important;
        }
        &:focus {
          box-shadow: none !important;
        }

        .p-button {
          box-shadow: none !important;
        }
      }
    }
  }

  .img-wrapper {
    position: relative;
    height: 250px;
    margin: 0 -1.25rem 40px;

    &::after {
      content: '';
      position: absolute;
      bottom: -20px;
      left: calc(50% - 125px);
      width: 250px;
      height: 250px;
      background-image: url('/assets/images/cognitive_library_admin_on_dark.png');
      background-size: cover;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 320px;
      background-image: url('/assets/images/dk_grid_on_dark.png');
      background-size: cover;
      opacity: 0.6;
    }
  }
}

.light {
  .entity-list-card {
    .img-wrapper {
      &::after {
        background-image: url('/assets/images/cognitive_library_admin_on_light.png');
      }
      &::before {
        background-image: url('/assets/images/dk_grid_on_light.png');
        opacity: 0.5;
      }
    }
  }
}
