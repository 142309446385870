.list-card {
  margin-bottom: 1.5rem;

  .p-datatable-wrapper {
    overflow: auto;
    height: calc(100vh - 310px);
  }
  .p-toolbar {
    padding-top: 5px;
    padding-bottom: 5px;
    border: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid var(--highlight-background);
  }

  .p-card-body {
    padding: 0rem !important;

    .p-card-content {
      padding: 0px !important;
    }
  }
}
