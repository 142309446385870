.custm-btn {
  margin-top: 1.7rem !important;
  padding: 0.35rem 0.875rem !important;
}

.custm-btn2 {
  margin-top: 1.7rem !important;
  padding: 0.35rem 0.875rem !important;
  color: #fff !important;
}
.geocode {
  margin-top: 34px;
}
label {
  color: #fff;
}
.form-steps {
  &.tenX {
    color: #000;
    background: var(--white-color);
    .nav-item {
      background: var(--10x-brand);
      a {
        border-color: var(--10x-brand) !important;
      }
    }
    input {
      background: var(--white-color);
      color: #000;
    }
    .p-button,
    .p-button:hover {
      background: var(--10x-brand);
      border: 1px solid var(--10x-brand);
    }
    label {
      color: #000;
    }
  }
}
